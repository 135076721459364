import { createHook, createStore } from 'react-sweet-state';
import { listProductRx } from '../../api';

const Store = createStore({
  name: 'ListProduct',
  initialState: {
    isLoading: true,
    type: 'normal',
    data: [],
    dataStatus: 1,
    all: true,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadListProduct:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });

        try {
          const res = await listProductRx(getState());

          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.products,
              total: res.data.payload.total,
              isLoading: false,
            });
          }
        } catch (error) {
          setState({
            error: error && error.message ? error.response.message : 'Data not found',
          });
        }
      },
  },
});

export const useListRXStore = createHook(Store);
