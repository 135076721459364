/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from './icon';
import { useAllListStore } from '../../../eClinic/doctor/stores/appointment';
import { useAuthStore } from '../../../auth/stores';
import { useAllListOrderStore } from '../../../eHealth/containers/order/stores';
import { useAllListStoreClinic } from '../../../eClinic/clinic/stores/appointment';

const Navigation = ({ items, activeItemId, onSelect }) => {
  const [authState] = useAuthStore();
  const [listAllStateDoctor, actionsAllDoctor] = useAllListStore();
  const [listAllStateClinic, actionsAllClinic] = useAllListStoreClinic();
  const [listOrder, actionListOrder] = useAllListOrderStore();
  const role = authState.data.roles[0].code;
  const [activeSubMenu, setActiveSubMenu] = useState({
    expanded: true,
    selectedId: activeItemId,
  });

  useEffect(() => {
    setActiveSubMenu((originSubMenu) => ({
      expanded: originSubMenu.expanded,
      selectedId: activeItemId,
    }));
  }, [activeItemId]);

  useEffect(() => {
    actionsAllDoctor.updateStore({
      doctor:
        authState?.data?.roles?.length !== 0 && role === 'SUPERADMIN' ? '' : authState?.data?.ID,
    });
    actionsAllDoctor.loadDataAll();

    actionListOrder.loadAllOrder();

    actionsAllClinic.loadDataAll();
  }, []);

  function handleClick(path) {
    onSelect?.({ path });
  }

  function handleSubMenuExpand(item) {
    if (activeSubMenu.expanded) {
      const currSubMenuIsOpen =
        item.path === activeSubMenu.selectedId ||
        (item.subMenu &&
          item.subMenu.some((_subMenuItem) => _subMenuItem.path === activeSubMenu.selectedId)) ||
        false;

      setActiveSubMenu({
        expanded: item.subMenu && item.subMenu.length > 0 ? !currSubMenuIsOpen : false,
        selectedId: item.path,
      });
    } else {
      setActiveSubMenu({
        expanded: !!(item.subMenu && item.subMenu.length > 0),
        selectedId: item.path,
      });
    }
  }

  const handleDot = (name) => {
    if (name?.includes('Doctor')) {
      const filtered = listAllStateDoctor.data?.filter((row) => {
        if (name === 'Request-Doctor') {
          if (row?.type === 'dhv' || row?.type === 'escort') {
            return row?.appointmentStatus === 10;
          }
          return row?.appointmentStatus === 10 && row?.payment?.paid === true;
        }
        if (name === 'Appointment-Doctor') {
          if (row?.type === 'dhv' || row?.type === 'escort') {
            return row?.appointmentStatus >= 20 && row?.appointmentStatus <= 30;
          }
          return (
            row?.appointmentStatus >= 20 &&
            row?.appointmentStatus <= 30 &&
            row?.payment?.paid === true
          );
        }
        return '';
      });
      if (filtered?.length > 0) {
        return (
          <span className="flex h-3 w-3 relative">
            <span className="animate-ping absolute inline-flex h-3 w-3 opacity-50 rounded-full bg-red-500" />
            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
          </span>
        );
      }
      return '';
    }
    if (name?.includes('Clinic')) {
      const filtered = listAllStateClinic.data?.filter((row) => {
        if (name === 'Request-Clinic') {
          return row?.appointmentStatus == 10;
        }
        if (name === 'Appointment-Clinic') {
          return row?.appointmentStatus >= 20 && row?.appointmentStatus <= 30;
        }
        return '';
      });
      if (filtered?.length > 0) {
        return (
          <span className="flex h-3 w-3 relative">
            <span className="animate-ping absolute inline-flex h-3 w-3 opacity-50 rounded-full bg-red-500" />
            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
          </span>
        );
      }
      return '';
    }
    return '';
  };

  const handleDotOrder = (name) => {
    const filtered = listOrder.data?.filter((row) => {
      if (name === 'Order') {
        return row?.orderStatus === 10;
      } else {
        return '';
      }
    });
    if (filtered?.length > 0) {
      return (
        <span className="flex h-3 w-3 relative">
          <span className="animate-ping absolute inline-flex h-3 w-3 opacity-50 rounded-full bg-red-500"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      );
    } else {
      return '';
    }
  };

  return (
    <>
      {items.length > 0 && (
        <nav role="navigation" aria-label="side-navigation" className="w-60">
          {items.map((item) => {
            const ElemBefore = item.elemBefore;
            const ActiveIcon = item.activeIcon;
            const isItemSelected = item.path === activeSubMenu.selectedId;
            const isActiveTab =
              activeSubMenu.expanded &&
              (isItemSelected ||
                (item.subMenu &&
                  item.subMenu.some(
                    (_subMenuItem) => _subMenuItem.path === activeSubMenu.selectedId,
                  )) ||
                false);

            return (
              <ul
                key={item.path}
                // className="pb-16"
                className="m-0 p-0 list-none"
              >
                <li key={item.id}>
                  <div
                    onClick={() => {
                      handleSubMenuExpand(item);
                      handleClick(item.path);
                    }}
                    className={`flex items-center pl-6 cursor-pointer text-sm leading-3 tracking-normal py-3 hover:text-nadi-secondary hover:bg-primary-focus focus:text-white rounded-lg focus:outline-none text-nadi-primary ${
                      isItemSelected ? 'bg-primary text-nadi-white' : ''
                    }`}
                  >
                    {/* <span className="">
                      {ElemBefore && <ElemBefore />}
                    </span>
                    <span className="">
                      {item.name}
                    </span> */}

                    <span className="flex items-center w-full pr-4 transition-transform transform hover:text-nadi-secondary hover:bg-primary-focus focus:text-white rounded-lg focus:outline-none">
                      {/* {ElemBefore && <ElemBefore />} */}
                      {isActiveTab ? <ActiveIcon /> : <ElemBefore />}

                      <span className="mx-4 font-medium">{item.name}</span>
                      <span className="mx-3 text-sm absolute right-10">
                        {handleDotOrder(item.name)}
                      </span>
                    </span>

                    {item.subMenu &&
                      item.subMenu.length > 0 &&
                      (isActiveTab ? <ChevronUpIcon /> : <ChevronDownIcon />)}
                  </div>
                </li>

                {item.subMenu && item.subMenu.length > 0 && isActiveTab && (
                  <ul className="m-0 p-0 list-none">
                    {item.subMenu.map((subMenuItem) => {
                      const SubItemElemBefore = subMenuItem.elemBefore;

                      return (
                        <li key={subMenuItem.path} className="m-0 p-0 list-none">
                          <div
                            onClick={() => {
                              setActiveSubMenu({
                                ...activeSubMenu,
                                selectedId: subMenuItem.path,
                              });
                              handleClick(subMenuItem.path);
                            }}
                            className={`flex items-center justify-between px-16 py-2 cursor-pointer text-nadi-primary rounded-lg ${
                              activeSubMenu.selectedId === subMenuItem.path
                                ? 'text-gray-800 bg-primary-focus'
                                : ''
                            } `}
                          >
                            <span className="flex items-center justify-end">
                              {/** Prefix Icon Component */}
                              {SubItemElemBefore && <SubItemElemBefore />}

                              <span className="mx-3 text-sm">{subMenuItem.name}</span>
                              <span className="mx-3 text-sm absolute right-10">
                                {handleDot(subMenuItem.label)}
                              </span>
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </ul>
            );
          })}
        </nav>
      )}
    </>
  );
};

export default Navigation;
