/* eslint-disable no-unused-expressions */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, lazy, Suspense } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Page404 from '../commons/pages/PageNotFound';
import { useAuthStore } from '../auth/stores';
import withRole from '../commons/rolebase/withRole';
import '../commons/styles/common.css';
// import Notification from '../notification/components/Notifications/Notifications';
import Notification from '../notification';

const Home = lazy(() => import('../home'));

// Privated Routes goes here
// -- put all Global routes here
// const GlobalHome = lazy(() => import('../global/containers/home'));
const GlobalChat = lazy(() => import('../utils/liveChat/components'));
const GlobalReport = lazy(() => import('../global/containers/report'));
const GlobalRating = lazy(() => import('../global/containers/rating'));
const GlobalBanner = lazy(() => import('../global/containers/banner'));
const GlobalArticle = lazy(() => import('../global/containers/article'));
const GlobalInsights = lazy(() => import('../global/containers/insights'));
const GlobalPartner = lazy(() => import('../global/containers/partner'));
const GlobalCustomer = lazy(() => import('../global/containers/customer'));
const GlobalHelpCenter = lazy(() => import('../global/containers/helpcenter'));
const GlobalMaintence = lazy(() => import('../global/containers/maintence'));
const GlobalSessions = withRole(['SUPERADMIN'])(
  lazy(() => import('../global/containers/userSession')),
);
const GlobalSubscription = withRole(['SUPERADMIN'])(
  lazy(() => import('../global/containers/subscription')),
);
const GlobalAdministrator = withRole(['SUPERADMIN', 'ICA'])(
  lazy(() => import('../global/containers/administrator')),
);
const GlobalHomeList = withRole(['SUPERADMIN', 'IAD', 'ECA'])(
  lazy(() => import('../global/containers/HomeNew')),
);

// -- put all eHealth routes here
const EHealthHome = lazy(() => import('../eHealth/containers/home'));
const EHealthChat = lazy(() => import('../eHealth/components/chat'));
const EHealthOrder = lazy(() => import('../eHealth/containers/order'));
const EHealthListProduct = lazy(() => import('../eHealth/containers/list-product'));
const EHealthListCategory = lazy(() => import('../eHealth/containers/list-category'));
const EHealthStoreFront = lazy(() => import('../eHealth/containers/storefront'));
const EHealthBanner = lazy(() => import('../eHealth/containers/banner'));
const EHealthBusinessInsight = lazy(() => import('../eHealth/containers/business-insight'));
const EHealthReview = lazy(() => import('../eHealth/containers/review'));
const EHealthPurchase = lazy(() => import('../eHealth/containers/purchase'));
const EHealthStockTransfer = lazy(() => import('../eHealth/containers/stock-transfer'));
const EHealthStoreSettings = lazy(() => import('../eHealth/containers/store-settings'));
const EHealthSpecialBrand = lazy(() => import('../eHealth/containers/special-brand'));
const EHealthTopProduct = lazy(() => import('../eHealth/containers/top-product'));
const EHealthIncomingPrescription = lazy(() =>
  import('../eHealth/containers/incoming-prescription'),
);
const EHealthPrescriptionConsultation = lazy(() =>
  import('../eHealth/containers/prescription-consultation'),
);

// -- put all eClinic routes here
// part doctor
const DoctorEClinicHome = lazy(() => import('../eClinic/doctor/containers/home'));
const DoctorEClinicChat = lazy(() => import('../eClinic/doctor/containers/chat'));
const DoctorEClinicRequest = lazy(() => import('../eClinic/doctor/containers/request'));
const DoctorEClinicVideo = lazy(() => import('../eClinic/doctor/containers/video'));
// limit with role access IAD (Internal Admin Doctor)
// const DoctorEClinicRequest = withRole(['SUPERADMIN', 'IAD'])(
//   lazy(() => import('../eClinic/doctor/containers/request'))
// );
const DoctorEClinicAppointment = lazy(() => import('../eClinic/doctor/containers/appointment'));
// limit with role access IAD (Internal Admin Doctor)
// const DoctorEClinicAppointment = withRole(['SUPERADMIN', 'IAD'])(
//   lazy(() => import('../eClinic/doctor/containers/appointment'))
// );

// part clinic
const ClinicEClinicHome = lazy(() => import('../eClinic/clinic/containers/home'));
const ClinicEClinicChat = lazy(() => import('../eClinic/clinic/containers/chat'));
const ClinicEClinicRequest = lazy(() => import('../eClinic/clinic/containers/request'));
const ClinicEClinicAppointment = lazy(() => import('../eClinic/clinic/containers/appointment'));

// part vaccination
const VaccinationEClinic = lazy(() => import('../eClinic/vaccination/containers'));

// -- put all myHappiness routes here
const MyHappiAnalytics = lazy(() => import('../myHappiness/containers/analytics'));
const MyHappiChat = lazy(() => import('../myHappiness/containers/chat'));
const MyHappiActivities = lazy(() => import('../myHappiness/containers/activities'));
const MyHappiLiveStream = lazy(() => import('../myHappiness/containers/live-stream'));
const MyHappiPodcast = lazy(() => import('../myHappiness/containers/podcast'));
const MyHappiVideos = lazy(() => import('../myHappiness/containers/videos'));
const MyHappiArticle = lazy(() => import('../myHappiness/containers/article'));
// end of Privated Routes goes here

// -- put all Role & Clinic component routes here
const RnCAppUserList = lazy(() => import('../partnership/containers/appUserList'));
const RnCPartnerListParent = lazy(() => import('../partnership/containers/partnerListParent'));
const RnCPartnerListMyHealth = lazy(() => import('../partnership/containers/partnerListMyHealth'));
const RnCPartnerListMyDoctor = lazy(() => import('../partnership/containers/partnerListMyDoctor'));
const RnCPartnerListMyHappiness = lazy(() =>
  import('../partnership/containers/partnerListMyHappiness'),
);
const RnCPlaceClinic = lazy(() => import('../partnership/containers/placeClinic'));
const RnCPlaceVaccinationCenter = lazy(() =>
  import('../partnership/containers/placeVaccinationCenter'),
);
const RnCNadiStaffList = lazy(() => import('../partnership/containers/nadiStaffList'));
const RnCServiceSetting = lazy(() => import('../partnership/containers/serviceSetting'));

function InlineLoader() {
  return <div id="cover-spin">{/* <h1>Loading ...</h1> */}</div>;
}

function PrivateRoute({ children, ...rest }) {
  const [authState, authActions] = useAuthStore();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function GeneralRoutes() {
  return (
    <Router>
      <Suspense fallback={<InlineLoader />}>
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
        </Switch>

        {/* Global Routes */}
        <Switch>
          {/* Global Home */}
          <PrivateRoute exact path="/global">
            <Redirect to="/global/dashboard" />
          </PrivateRoute>

          <PrivateRoute path="/global/dashboard">
            <Suspense fallback={<InlineLoader />}>
              <GlobalHomeList />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute path="/global/user-session">
            <Suspense fallback={<InlineLoader />}>
              <GlobalSessions />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/global/subscription">
            <Suspense fallback={<InlineLoader />}>
              <GlobalSubscription />
            </Suspense>
          </PrivateRoute>

          {/* Global Chat */}
          <PrivateRoute path="/global/chat">
            <Suspense fallback={<InlineLoader />}>
              <GlobalChat />
            </Suspense>
          </PrivateRoute>

          {/* Global Report */}
          <PrivateRoute path="/global/report">
            <Suspense fallback={<InlineLoader />}>
              <GlobalReport />
            </Suspense>
          </PrivateRoute>

          {/* Global Rating */}
          <PrivateRoute path="/global/rating">
            <Suspense fallback={<InlineLoader />}>
              <GlobalRating />
            </Suspense>
          </PrivateRoute>

          {/* Global Banner */}
          <PrivateRoute path="/global/banner">
            <Suspense fallback={<InlineLoader />}>
              <GlobalBanner />
            </Suspense>
          </PrivateRoute>

          {/* Global Article */}
          <PrivateRoute path="/global/article">
            <Suspense fallback={<InlineLoader />}>
              <GlobalArticle />
            </Suspense>
          </PrivateRoute>

          {/* Global Insights */}
          <PrivateRoute path="/global/insights">
            <Suspense fallback={<InlineLoader />}>
              <GlobalInsights />
            </Suspense>
          </PrivateRoute>

          {/* Global Partner */}
          <PrivateRoute path="/global/partner">
            <Suspense fallback={<InlineLoader />}>
              <GlobalPartner />
            </Suspense>
          </PrivateRoute>

          {/* Global Customer */}
          <PrivateRoute path="/global/customer">
            <Suspense fallback={<InlineLoader />}>
              <GlobalCustomer />
            </Suspense>
          </PrivateRoute>

          {/* Global Help Center */}
          <PrivateRoute path="/global/helpcenter">
            <Suspense fallback={<InlineLoader />}>
              <GlobalHelpCenter />
            </Suspense>
          </PrivateRoute>

          {/* Global Maintence */}
          <PrivateRoute path="/global/maintence">
            <Suspense fallback={<InlineLoader />}>
              <GlobalMaintence />
            </Suspense>
          </PrivateRoute>
          {/* Global Home Angga */}
          {/* <PrivateRoute path="/global/home-angga">
            <Suspense fallback={<InlineLoader />}>
              <GlobalHomeAngga />
            </Suspense>
          </PrivateRoute> */}

          {/* Global Administrator */}
          {/* {withRole(['Super Admin'])(() => (
            <PrivateRoute path="/global/administrator">
              <Suspense fallback={<InlineLoader />}>
                <GlobalAdministrator />
              </Suspense>
            </PrivateRoute>
          ))} */}

          <PrivateRoute path="/global/administrator">
            <Suspense fallback={<InlineLoader />}>
              <GlobalAdministrator />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute exact path="/global/*">
            <Page404 />
          </PrivateRoute>
        </Switch>

        {/* E-Health Routes */}
        <Switch>
          {/* E-Health Home */}
          <PrivateRoute exact path="/e-health">
            <Redirect to="/e-health/dashboard" />
          </PrivateRoute>

          <PrivateRoute path="/e-health/dashboard">
            <Suspense fallback={<InlineLoader />}>
              <EHealthHome />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Chat */}
          <PrivateRoute path="/e-health/chat">
            <Suspense fallback={<InlineLoader />}>
              <EHealthChat />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Order */}
          <PrivateRoute path="/e-health/order">
            <Suspense fallback={<InlineLoader />}>
              <EHealthOrder />
            </Suspense>
          </PrivateRoute>

          {/* E-Health List Product */}
          <PrivateRoute path="/e-health/list-product">
            <Suspense fallback={<InlineLoader />}>
              <EHealthListProduct />
            </Suspense>
          </PrivateRoute>

          {/* E-Health List Category */}
          <PrivateRoute path="/e-health/list-category">
            <Suspense fallback={<InlineLoader />}>
              <EHealthListCategory />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Store Front */}
          <PrivateRoute path="/e-health/storefront">
            <Suspense fallback={<InlineLoader />}>
              <EHealthStoreFront />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Banner */}
          <PrivateRoute path="/e-health/banner">
            <Suspense fallback={<InlineLoader />}>
              <EHealthBanner />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Business Insight */}
          <PrivateRoute path="/e-health/business-insight">
            <Suspense fallback={<InlineLoader />}>
              <EHealthBusinessInsight />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Review */}
          <PrivateRoute path="/e-health/review">
            <Suspense fallback={<InlineLoader />}>
              <EHealthReview />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Purchase */}
          <PrivateRoute path="/e-health/purchase">
            <Suspense fallback={<InlineLoader />}>
              <EHealthPurchase />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Stock Transfer */}
          <PrivateRoute path="/e-health/stock-transfer">
            <Suspense fallback={<InlineLoader />}>
              <EHealthStockTransfer />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Store Settings */}
          <PrivateRoute path="/e-health/store-settings">
            <Suspense fallback={<InlineLoader />}>
              <EHealthStoreSettings />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Special Brand */}
          <PrivateRoute path="/e-health/special-brand">
            <Suspense fallback={<InlineLoader />}>
              <EHealthSpecialBrand />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Top Product */}
          <PrivateRoute path="/e-health/top-product">
            <Suspense fallback={<InlineLoader />}>
              <EHealthTopProduct />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Prescription Consultation */}
          <PrivateRoute path="/e-health/prescription-consultation">
            <Suspense fallback={<InlineLoader />}>
              <EHealthPrescriptionConsultation />
            </Suspense>
          </PrivateRoute>

          {/* E-Health Incoming Prescription */}
          <PrivateRoute path="/e-health/incoming-prescription">
            <Suspense fallback={<InlineLoader />}>
              <EHealthIncomingPrescription />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute exact path="/e-health/*">
            <Page404 />
          </PrivateRoute>
        </Switch>

        {/* E-Clinic Routes */}
        {/* Part Doctor */}
        <Switch>
          {/* EClinicHome */}
          <PrivateRoute exact path="/e-clinic">
            <Redirect to="/e-clinic/dashboard" />
          </PrivateRoute>

          <PrivateRoute path="/e-clinic/dashboard">
            <Suspense fallback={<InlineLoader />}>
              <DoctorEClinicHome />
            </Suspense>
          </PrivateRoute>
          {/* EClinicChat */}
          <PrivateRoute path="/e-clinic/chat">
            <Suspense fallback={<InlineLoader />}>
              <DoctorEClinicChat />
            </Suspense>
          </PrivateRoute>
          {/* EClinicVideo */}
          <PrivateRoute path="/e-clinic/video">
            <Suspense fallback={<InlineLoader />}>
              <DoctorEClinicVideo />
            </Suspense>
          </PrivateRoute>
          {/* EClinicRequest */}
          <PrivateRoute path="/e-clinic/doctor/request">
            <Suspense fallback={<InlineLoader />}>
              <DoctorEClinicRequest />
            </Suspense>
          </PrivateRoute>
          {/* EClinicAppointment */}
          <PrivateRoute path="/e-clinic/doctor/appointment">
            <Suspense fallback={<InlineLoader />}>
              <DoctorEClinicAppointment />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/e-clinic/doctor">
            <Redirect to="/e-clinic/doctor/request" />
          </PrivateRoute>
          <PrivateRoute exact path="/e-clinic/doctor/*">
            <Page404 />
          </PrivateRoute>
        </Switch>

        {/* Part Clinic */}
        <Switch>
          {/* EClinicHome */}
          <PrivateRoute exact path="/e-clinic">
            <Redirect to="/e-clinic/dashboard" />
          </PrivateRoute>

          <PrivateRoute path="/e-clinic/dashboard">
            <Suspense fallback={<InlineLoader />}>
              <ClinicEClinicHome />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/e-clinic/chat">
            <Suspense fallback={<InlineLoader />}>
              <ClinicEClinicChat />
            </Suspense>
          </PrivateRoute>
          {/* EClinicRequest */}
          <PrivateRoute path="/e-clinic/clinic/request">
            <Suspense fallback={<InlineLoader />}>
              <ClinicEClinicRequest />
            </Suspense>
          </PrivateRoute>
          {/* EClinicAppointment */}
          <PrivateRoute path="/e-clinic/clinic/appointment">
            <Suspense fallback={<InlineLoader />}>
              <ClinicEClinicAppointment />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/e-clinic/clinic">
            <Redirect to="/e-clinic/clinic/request" />
          </PrivateRoute>
          <PrivateRoute exact path="/e-clinic/clinic/*">
            <Page404 />
          </PrivateRoute>
        </Switch>

        <Switch>
          <PrivateRoute path="/e-clinic/vaccination">
            <Suspense fallback={<InlineLoader />}>
              <VaccinationEClinic />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/e-clinic/vaccination/*">
            <Page404 />
          </PrivateRoute>
        </Switch>
        {/* end of E-Clinic Routes */}

        {/* My-Happiness */}
        <Switch>
          {/* MyHappiAnalytics */}
          <PrivateRoute exact path="/my-happiness">
            <Redirect to="/my-happiness/dashboard" />
          </PrivateRoute>

          <PrivateRoute path="/my-happiness/dashboard">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiAnalytics />
            </Suspense>
          </PrivateRoute>

          {/* MyHappiChat */}
          <PrivateRoute path="/my-happiness/chat">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiChat />
            </Suspense>
          </PrivateRoute>

          {/* MyHappiActivities */}
          <PrivateRoute path="/my-happiness/activities">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiActivities />
            </Suspense>
          </PrivateRoute>

          {/* MyHappiLiveStream */}
          <PrivateRoute path="/my-happiness/live-stream">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiLiveStream />
            </Suspense>
          </PrivateRoute>

          {/* MyHappiArticle */}
          <PrivateRoute path="/my-happiness/article">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiArticle />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute path="/my-happiness/add">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiArticle />
            </Suspense>
          </PrivateRoute>

          {/* MyHappiPodcast */}
          <PrivateRoute path="/my-happiness/podcast">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiPodcast />
            </Suspense>
          </PrivateRoute>

          {/* MyHappiVideos */}
          <PrivateRoute path="/my-happiness/videos">
            <Suspense fallback={<InlineLoader />}>
              <MyHappiVideos />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute exact path="/my-happiness/*">
            <Page404 />
          </PrivateRoute>
        </Switch>
        {/* end of MyHappi routes */}

        {/* Partnership Role & Clinic routes */}
        <Switch>
          {/* App User List */}
          <PrivateRoute exact path="/partnership">
            <Redirect to="/partnership/app-user-list" />
          </PrivateRoute>

          <PrivateRoute path="/partnership/app-user-list">
            <Suspense fallback={<InlineLoader />}>
              <RnCAppUserList />
            </Suspense>
          </PrivateRoute>

          {/* Partner List Parent */}
          <PrivateRoute path="/partnership/partner-list">
            <Suspense fallback={<InlineLoader />}>
              <RnCPartnerListParent />
            </Suspense>
          </PrivateRoute>

          {/* Partner List My Health */}
          <PrivateRoute path="/partnership/partner-list/my-health">
            <Suspense fallback={<InlineLoader />}>
              <RnCPartnerListMyHealth />
            </Suspense>
          </PrivateRoute>

          {/* Partner List My Doctor */}
          <PrivateRoute path="/partnership/partner-list/my-doctor">
            <Suspense fallback={<InlineLoader />}>
              <RnCPartnerListMyDoctor />
            </Suspense>
          </PrivateRoute>

          {/* Partner List My Happiness */}
          <PrivateRoute path="/partnership/partner-list/my-happiness">
            <Suspense fallback={<InlineLoader />}>
              <RnCPartnerListMyHappiness />
            </Suspense>
          </PrivateRoute>

          {/* Place Clinic */}
          <PrivateRoute exact path="/partnership/place">
            <Redirect to="/partnership/place/clinic" />
          </PrivateRoute>

          <PrivateRoute path="/partnership/place/clinic">
            <Suspense fallback={<InlineLoader />}>
              <RnCPlaceClinic />
            </Suspense>
          </PrivateRoute>

          {/* Place Vaccination Center */}
          <PrivateRoute path="/partnership/place/vaccination-center">
            <Suspense fallback={<InlineLoader />}>
              <RnCPlaceVaccinationCenter />
            </Suspense>
          </PrivateRoute>

          {/* Nadi Staff List */}
          <PrivateRoute path="/partnership/nadi-staff-list">
            <Suspense fallback={<InlineLoader />}>
              <RnCNadiStaffList />
            </Suspense>
          </PrivateRoute>

          {/* Service Setting */}
          <PrivateRoute path="/partnership/service-setting">
            <Suspense fallback={<InlineLoader />}>
              <RnCServiceSetting />
            </Suspense>
          </PrivateRoute>

          <PrivateRoute exact path="/partnership/*">
            <Page404 />
          </PrivateRoute>
        </Switch>

        {/* Notification */}
        <Switch>
          {/* App User List */}
          <PrivateRoute exact path="/notification">
            <Suspense fallback={<InlineLoader />}>
              <Notification />
            </Suspense>
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default function index() {
  return <GeneralRoutes />;
}
