import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Page404 from '../commons/pages/PageNotFound';
import Home from './Home';
// import Detail from './Detail';

export default function UserManajement() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        {/* <Route path={`${path}/detail/:id`}>
          <Detail />
        </Route> */}
        <Route path={`${path}/*`}>
          <Page404 />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
