/* eslint-disable max-len */
import { apiClient } from '../../../commons/services';

const prefix = '/v2/admin';
const prefixOld = '/v1/admin';
const basePathImage = '/v1/admin/livechat/presigned/photo';
// const basePathAudio = '/v1/admin/livechat/presigned/audio';
const basePathAudio = '/v2/admin/eclinic/appointment/livechat/audio/presigned';
const basePathVideo = '/v2/admin/eclinic/appointment/livechat/video/presigned';
const basePathDocument = '/v2/admin/eclinic/appointment/livechat/document/presigned';

export const listRequestApi = ({
  limit,
  skip,
  sort,
  sortby,
  doctor,
  type,
  status,
  search,
  startDate,
  endDate,
  available,
  method,
  paid,
}) =>
  apiClient.get(
    `${prefix}/eclinic/appointment/request/list?skip=${skip}&limit=${limit}&sortdir=${sort}&sortby=${sortby}&paid=${paid}&type=${type}&status=${status}&search=${search}&doctor=${doctor}&startAt=${startDate}&endAt=${endDate}&available=${available}&method=${method}`,
  );

export const listAppointmentApi = ({
  limit,
  skip,
  sort,
  sortby,
  doctor,
  type,
  status,
  search,
  startDate,
  endDate,
  available,
  paid,
}) =>
  apiClient.get(
    `${prefix}/eclinic/appointment/list?skip=${skip}&limit=${limit}&sortdir=${sort}&sortby=${sortby}&paid=${paid}&type=${type}&status=${status}&search=${search}&doctor=${doctor}&startAt=${startDate}&endAt=${endDate}&available=${available}`,
  );

export const updateStatusAppointment = (body) =>
  apiClient.post(`${prefix}/eclinic/appointment/request/update`, body);

export const detailApi = (id) => apiClient.get(`${prefix}/eclinic/appointment/${id}`);

export const listPatientDiagnose = (
  { limit, skip, sort, search, startDate, endDate },
  customerId,
) =>
  apiClient.get(
    `${prefixOld}/eclinic/medical-records/list/${customerId}?skip=${skip}&limit=${limit}&sortdir=${sort}&search=${search}&startAt=${startDate}&endAt=${endDate}`,
  );

export const listClinic = () => apiClient.get(`${prefix}/eclinic/clinic/list`);

export const addPatientDiagnose = ({
  type,
  isPrescription,
  description,
  doctorId,
  userId,
  appointmentId,
}) => {
  return apiClient.post(`${prefix}/eclinic/patient-diagnosis/admin/add`, {
    type,
    isPrescription,
    description,
    doctorId,
    userId,
    appointmentId,
  });
};

export const deletePatientDiagnose = (id) =>
  apiClient.post(`${prefixOld}/eclinic/medical-records/delete/${id}`);

export const listService = ({ limit, skip }) =>
  apiClient.get(`${prefix}/eclinic/doctor/services/list?limit=${limit}&skip=${skip}`);

export const listProductRx = ({ dataStatus, all }) =>
  apiClient.get(
    `${prefixOld}/ehealth/products/list?datastatus=${dataStatus}&all=${all}&has_qty=true&has_weight=true`,
  );

export const confirmPayment = ({ appointmentId, payment }) =>
  apiClient.post(`${prefix}/eclinic/appointment/payment/update`, {
    appointmentId,
    payment,
  });

export const doctorSign = (body) => apiClient.post(`${prefixOld}/ehealth/rx/add-with-sign`, body);
export const callbackSign = (rxId) =>
  apiClient.get(`${prefixOld}/ehealth/rx/callback-with-sign/${rxId}`);
export const updateStatusRx = (rxId) =>
  apiClient.post(`${prefixOld}/ehealth/rx/update-status-with-sign/${rxId}`, {
    rxStatus: 4,
  });
export const updateAppointment = (body) =>
  apiClient.post(`${prefix}/eclinic/appointment/update`, body);

export const adminDoctorList = () => apiClient.get(`${prefixOld}/eclinic/doctor/list-admin-doctor`);
export const presignedImageApi = (extension) => {
  return apiClient.get(`${basePathImage}/${extension}`);
};
export const deleteReferApi = (body) => {
  return apiClient.post(`${prefix}/eclinic/appointment/refer/remove`, body);
};
export const presignedVoiceApi = (extension) => {
  return apiClient.get(`${basePathAudio}/${extension}`);
};
export const addMedicalHistory = (body) => {
  return apiClient.post(`${prefixOld}/eclinic/medical-records/add`, body);
};

export const deleteRxApi = (id) => {
  return apiClient.post(`${prefixOld}/ehealth/rx/delete/${id}`);
};

export const updateDoctorActive = (id, body) => {
  return apiClient.post(`${prefixOld}/doctors/inroom/${id}`, body);
};

export const presignedVideoApi = (extension) => {
  return apiClient.get(`${basePathVideo}/${extension}`);
};

export const presignedDocumentApi = (extension) => {
  return apiClient.get(`${basePathDocument}/${extension}`);
};
