import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import PaymentResolve from '../commons/pages/PaymentResolveRedirect/components/PaymentResolve';
// import { useAuthStore } from './stores';

export default function Auth() {
  // eslint-disable-next-line no-unused-vars
  // const [authState, authActions] = useAuthStore();
  return (
    <Router>
      <Switch>
        <Route path="login" component={LoginPage} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:_id" component={ResetPassword} />
        <Route path="/payment-resolve" component={PaymentResolve} />
        <Route path="/payment-success" component={() => <p>payment success</p>} />
        <Route path="/payment-failed" component={() => <p>payment failed</p>} />
        <Route path="*" component={LoginPage} />
      </Switch>
    </Router>
  );
}
