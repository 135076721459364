import { createStore, createHook } from 'react-sweet-state';
import { updateAppointment, updateDoctorActive } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    error: null,
    data: 'halo',
  },
  actions: {
    updateData:
      (body) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null, data: 'halo' });

        try {
          await updateAppointment(body);

          setState({ data: 'halo', isLoading: false });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }
      },

    updateDoctorActive:
      (doctorId, body) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          await updateDoctorActive(doctorId, body);

          setState({ isLoading: false });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }
      },
  },
  name: 'updateAppointmentEClinic',
});

export const useUpdateAppointmentStore = createHook(Store);
