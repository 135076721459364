import React, { useEffect } from 'react';
import { Header, FormBoxForgotPassword } from './components';

export default function ForgotPassword() {
  useEffect(() => {
    document.title = 'Forgot Password - Nadi Admin';
  }, []);

  return (
    <div className="App">
      <Header mode="forgot-password" />

      <div className="content-center mt-8 mx-8 py-10 w-2/6">
        <FormBoxForgotPassword />
      </div>
    </div>
  );
}
