import { createStore, createHook } from 'react-sweet-state';
import { preferableTime } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    error: null,
  },
  actions: {
    actionUpdate:
      (body) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          await preferableTime(body);
          setState({ isLoading: false });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'preferableTimeClinic',
});

export const usePreferableTimeStore = createHook(Store);
