import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { FormLogin } from './components';
import img from './img/login_illus.svg';
import { useAuthStore } from './stores';
import NadiLogo from './img/logo.png';

export default function LoginPage() {
  // eslint-disable-next-line no-unused-vars
  const [authState] = useAuthStore();
  useEffect(() => {
    document.title = 'Login - Nadi Admin';
  }, []);

  if (authState.isAuth) return <Redirect to={{ pathname: '/' }} />;

  return (
    <>
      <div className="min-h-screen bg-white flex">
        <div className="hidden lg:block relative w-0 m-10 flex-1">
          <img className="absolute inset-0 w-600 object-cover" src={img} alt="" />
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={NadiLogo} alt="Nadi Logo" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Sign in to your account
              </h2>
            </div>
            <FormLogin />
          </div>
        </div>
      </div>
    </>
  );
}
