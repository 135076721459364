/* eslint-disable no-unused-vars */
import { createStore, createHook } from 'react-sweet-state';
import { checkAuth, loginAuth, checkResetPass, reset } from '../api';

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    initialAuth: false,
    isLoading: false,
    isAuth: false,
    data: {},
    error: null,
    errorReset: null,
    resetNotValid: false,
    resetSuccess: false,
  },
  actions: {
    checkResetPass:
      ({ _id }) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          const resp = await checkResetPass({ _id });

          if (resp.data && resp.data.code === 200) {
            setState({ resetNotValid: false });
          }
        } catch (err) {
          setState({
            resetNotValid: true,
            errorReset: err && err.response ? err.response.message : 'Token not valid',
          });
        }
        setState({ isLoading: false });
      },
    reset:
      ({ _id, password }) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          const resp = await reset({ _id, password });

          if (resp.data && resp.data.code === 200) {
            setState({ resetSuccess: true });
          }
        } catch (err) {
          setState({
            resetNotValid: true,
            error: err && err.response ? err.response.message : 'Token not valid',
          });
        }
        setState({ isLoading: false, error: null });
      },
  },
  // optional, mostly used for easy debugging
  name: 'reset',
});

export const useResetStore = createHook(Store);
