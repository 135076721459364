import { createStore, createHook } from 'react-sweet-state';
import { doctorSign } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    error: null,
    data: [],
  },
  actions: {
    updateData:
      (body) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          const res = await doctorSign(body);
          console.log(res);
          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload,
              isLoading: false,
            });
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'doctorSignDoctorEClinic',
});

export const useDoctorSignStore = createHook(Store);
