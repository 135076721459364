import moment from 'moment';
import React from 'react';

export default function ListNotificationMyPharma({ item, handleLink }) {
  return (
    <>
      <a
        key={item.name}
        href={handleLink(item)}
        className={`flex items-center w-full ${
          item.isRead ? '' : 'mypharma'
        } bordered border p-2 -m-3 transition duration-150 ease-in-out rounded-lg cursor-pointer focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
      >
        <div className="indicator ml-4 w-full">
          {/* <span className="indicator-item badge badge-primary" /> */}
          <div className="p-4 w-full hover:bg-base-200 place-items-center rounded-lg">
            <div className="flex justify-between mb-1">
              <div className="flex row">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.65332 15.1097C0.65332 14.1991 1.39148 13.4609 2.30205 13.4609H15.578C16.4886 13.4609 17.2268 14.1991 17.2268 15.1097V17.58C17.2268 18.0353 16.8577 18.4044 16.4024 18.4044H1.47769C1.0224 18.4044 0.65332 18.0353 0.65332 17.58V15.1097Z" fill="#21485C" />
                  <path d="M15.5967 8.37695C15.5967 7.27238 14.7013 6.37695 13.5967 6.37695H4.27441C3.16984 6.37695 2.27441 7.27238 2.27441 8.37695V14.7615C2.27441 15.8661 3.16984 16.7615 4.27441 16.7615H13.5967C14.7013 16.7615 15.5967 15.8661 15.5967 14.7615V8.37695Z" fill="#28DAD1" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.6423 16.761H6.23535V12.5396C6.23535 11.2556 7.44823 10.2148 8.93315 10.2148C10.4294 10.2148 11.6423 11.2556 11.6423 12.5396V16.761Z" fill="#1D9E98" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.9776 16.76H6.90137V13.0279C6.90137 11.8928 7.81574 10.9727 8.93521 10.9727C10.0632 10.9727 10.9776 11.8928 10.9776 13.0279V16.76Z" fill="#A9F0ED" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5625 6.37551H8.93536H17.3163L14.3369 2.08008H8.93536H3.54185L0.5625 6.37551Z" fill="#FFA11D" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M17.3006 6.37695C17.1714 7.6656 15.9684 8.68915 14.5231 8.68915C13.0778 8.68915 11.8748 7.6656 11.7456 6.37695H17.3006ZM11.7214 6.37695C11.5922 7.6656 10.3892 8.68915 8.94389 8.68915H8.93581C7.49055 8.68915 6.2875 7.6656 6.15832 6.37695H11.7214ZM6.1341 6.37695C6.00491 7.6656 4.80187 8.68915 3.3566 8.68915C1.91133 8.68915 0.708287 7.6656 0.579102 6.37695H6.1341Z" fill="#FF6D11" />
                  <path d="M13.6231 0.546875H4.256C3.86166 0.546875 3.54199 0.866547 3.54199 1.26088V2.07288H14.3371V1.26088C14.3371 0.866547 14.0174 0.546875 13.6231 0.546875Z" fill="#1D9E98" />
                  <path d="M6.50064 2.41382L5.78119 5.86198L5.77807 5.87692L5.77618 5.89206C5.74797 6.11774 5.88549 6.35696 6.13536 6.41942C6.27945 6.45545 6.41437 6.4167 6.50992 6.35301C6.60074 6.29246 6.68788 6.18766 6.71236 6.04505L7.43222 2.59492L7.43586 2.57746L7.43783 2.55974C7.4594 2.36556 7.35207 2.07882 7.05006 2.03149C6.93762 2.01097 6.81249 2.02858 6.70385 2.09961C6.59288 2.17217 6.52136 2.28658 6.50064 2.41382Z" fill="#FFD8A2" stroke="#FFD8A2" strokeWidth="0.75" />
                  <path d="M10.4416 2.55974L10.4436 2.57746L10.4472 2.59492L11.1671 6.04505C11.1915 6.18766 11.2787 6.29246 11.3695 6.35301C11.465 6.4167 11.6 6.45544 11.7441 6.41942C11.9939 6.35695 12.1315 6.11774 12.1032 5.89206L12.1013 5.87692L12.0982 5.86198L11.3788 2.41382C11.3581 2.28658 11.2865 2.17217 11.1756 2.09961C11.0669 2.02858 10.9418 2.01097 10.8294 2.03149C10.5274 2.07882 10.42 2.36556 10.4416 2.55974Z" fill="#FFD8A2" stroke="#FFD8A2" strokeWidth="0.75" />
                  <path d="M9.87742 14.5146C10.1279 14.5146 10.331 14.3115 10.331 14.061C10.331 13.8105 10.1279 13.6074 9.87742 13.6074C9.62691 13.6074 9.42383 13.8105 9.42383 14.061C9.42383 14.3115 9.62691 14.5146 9.87742 14.5146Z" fill="#21485C" />
                </svg>
                <p className="text-sm mx-2 custom-notification-title">
                  {item.module} - {item.title}
                </p>
                {
                  item.isRead
                  ? ''
                  :
                  <svg className="mt-1" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4.54688" r="4" fill="#FF5947" />
                  </svg>
                }
              </div>
              <p className="text-xs text-gray-500">
                {moment(item.dateTime).format('hh:mm A DD MMM YYYY')}
              </p>
            </div>
            <p className="text-sm custom-notification-order">
              Order No:
              {' '}
              <span className="custom-notification-order__span">839202021</span>
            </p>
            <p className="text-sm custom-notification-description">{item.message}</p>
          </div>
        </div>
      </a>
    </>
  );
}
