/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { useAuthStore } from '../../auth/stores';
import { NadiLogo } from '../../assets/icons/NadiLogo';
import { ReactComponent as NotificationBell } from '../../assets/icons/bell.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/eclinic/chat.svg';
// import { SearchLoop } from '../../assets/icons/SearchLoop';
import { useListNotifStore } from '../../notification/stores';
import { initChat, logOut } from '../../utils/liveChat/chat-sdk'

// {/* 1. NavLeft */}
function NavLeft(props) {
  const { drawerMode, handleShrink } = props;
  return (
    <>
      {/* --1.1 btn collapsed sibebar */}
      {drawerMode && (
        <div className="flex-none w-48 justify-center items-center hidden lg:flex">
          <NadiLogo />
        </div>
      )}
      <div className="flex-none hidden lg:flex">
        {/* {!drawerMode && (
          <button
            type="button"
            className="btn btn-sm btn-ghost shadow-md hover:shadow-lg mr-3"
            onClick={handleShrink}
          >
            <svg
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
              transform="rotate(0)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                // d="M6.5 1L1 6.5L6.5 12"
                d="M6.5 1L1 6.5L6.5 12"
                stroke="#7B7D8B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )} */}
      </div>
      {/* --1.2 Search-input */}
      <div className="flex-1 ml-4">
        <div className="form-control">
          {/* <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                <SearchLoop />
              </button>
            </span>
            <input
              style={{ backgroundColor: '#F8F8F8' }}
              type="text"
              placeholder="Search anything..."
              className="input input-ghost rounded-full w-60 lg:w-96 pl-10"
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

NavLeft.propTypes = {
  handleShrink: PropTypes.func,
  drawerMode: PropTypes.bool,
};

NavLeft.defaultProps = {
  handleShrink: null,
  drawerMode: false,
};

// {/* 2. NavRight */}
function NavRight() {
  const [authState, authActions] = useAuthStore();
  const [notifState, notifActions] = useListNotifStore();
  const history = useHistory();
  const location = useLocation();
  const [roleUser, setRoleUser] = React.useState('-');

  // const [notificationList, setNotificationList] = useState(DUMMY_DATA);

  useEffect(() => {
    if (authState.data.roles.length > 0) {
      const isIncludedName = Array.from(authState.data.roles);
      if (isIncludedName[0]?.name) {
        const { name } = authState.data.roles[0];
        setRoleUser(name);
      }
    }
  }, [authState.data.roles]);

  useEffect(() => {
    authActions.reloadDataUser();
    notifActions.loadData();
  }, []);

  // useEffect(() => {
  //   const hasToken = !!authState.data.livechatToken;
  //   if (hasToken && authState.data.isAvailable) {
  //     initChat(authState.data.livechatToken);
  //   } else if (hasToken && !authState.data.isAvailable) {
  //     logOut();
  //   }
  // }, [authState.data.isAvailable]);

  // function handleBellNotification() {
  // notifActions.getNotifications();
  // console.log('notify handle');
  // history.push('/notification');
  // }

  const handleToggleAvailability = async (availability) => {
    const id = authState.data.ID;
    await authActions.setMyAvailability({ id, availability });
    authActions.reloadDataUser();
  };

  // console.log(authState.data);
  const hasToken = !!authState.data.livechatToken;
  let chatLink;
  switch(window.localStorage.getItem('chimba-mnulvl')) {
    case '0' : chatLink = '/#/global/chat'; break;
    case '1' : chatLink = '/#/e-health/chat'; break;
    case '2' : chatLink = '/#/e-clinic/chat'; break;
  }
  const chatAvailabale = ['0', '1', '2'];
  const hasChatMenu = chatAvailabale.includes(window.localStorage.getItem('chimba-mnulvl'));
  return (
    <>
      {/* 2.1 Notification badge */}
      <div className="flex-none my-7 mx-3 indicator">
        <div
          className={`border-2 border-white ${
            !notifState.data.length < 1 ? 'indicator-item badge-xs badge badge-accent' : ''
          }`}
        />
        { hasToken && hasChatMenu && (
          <button
            type="button"
            className="p-1 hover:bg-gray-200"
            onClick={() =>
              window.open(`${ chatLink }`, 'chatwindow', 'width=1024,height=700,status=0')
            }
          >
            <ChatIcon />
          </button>
        ) }
        <button
          type="button"
          className="p-1 hover:bg-gray-200"
          onClick={() => {
            history.push('/notification');
          }}
        >
          <NotificationBell />
        </button>
      </div>
      <div style={{ borderLeft: '3px solid lightgray' }} className="h-10 ml-2 mr-2" />
      {/* 2.2 User Avatar Profile */}
      <Menu as="div" className="dropdown">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="flex items-center ml-4 text-gray-700 mr-4">
                <div className="flex">
                  <div
                    className={`${authState.data.isAvailable ? 'avatar online' : 'avatar offline'}`}
                  >
                    <div className="rounded-full w-11 h-11 m-1">
                      {authState.data.photoProfile ? (
                        <img src={authState.data.photoProfile} alt="avatar" />
                      ) : (
                        <img src="https://i.pravatar.cc/500?img=32" alt="avatar" />
                      )}
                    </div>
                  </div>
                  <div className="ml-3 mt-1 text-left text-nadi-primary">
                    <h5 className="hidden lg:flex">{authState.data.displayName}</h5>
                    <div className="hidden lg:flex overflow-x-auto items-center">
                      <div className="flex-initial bg-sky-700 text-nadi-secondary text-sm">
                        {/* auth structure logic for new access role structure */}
                        {roleUser}
                      </div>
                    </div>
                  </div>
                  <span className="btn btn-ghost">
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.29289 7.29289L0.707107 1.70711C0.0771421 1.07714 0.523308 0 1.41421 0H12.5858C13.4767 0 13.9229 1.07714 13.2929 1.70711L7.70711 7.29289C7.31658 7.68342 6.68342 7.68342 6.29289 7.29289Z"
                        fill="#3A4F5C"
                      />
                    </svg>
                  </span>
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-7 w-52 mt-36 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? 'bg-violet-500 hover:text-white hover:bg-gray-500'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => handleToggleAvailability(!authState.data.isAvailable)}
                      >
                        {authState.data.isAvailable ? 'Set as unavailable' : 'Set as available'}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? 'bg-violet-500 hover:text-white hover:bg-gray-500'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={async () => {
                          await authActions.updateStore({
                            isBtnLogout: true
                          })
                          await handleToggleAvailability(false)
                          history.push('/');
                          await authActions.logout();
                        }}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
}

function NavWrapper({ children }) {
  return <header className="navbar min-w-full z-50">{children}</header>;
}

NavWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Header(props) {
  const { drawerMode, onShrink } = props;
  return (
    <NavWrapper>
      <NavLeft handleShrink={onShrink} drawerMode={drawerMode} />
      <NavRight />
    </NavWrapper>
  );
}

Header.propTypes = {
  onShrink: PropTypes.func,
  drawerMode: PropTypes.bool,
};

Header.defaultProps = {
  onShrink: null,
  drawerMode: false,
};
