/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const NavContext = React.createContext();
const NavUpdateContext = React.createContext();

export const useNavigation = () => useContext(NavContext);
export const useNavigationUpdate = () => useContext(NavUpdateContext);

function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    console.warn(e);
  }
}

function getLocalStorage(key, initialValue) {
  try {
    const value = window.localStorage.getItem(key);
    return value || initialValue;
  } catch (e) {
    return initialValue;
  }
}
export function NavProvider({ children }) {
  const initialState = 0;
  const [navValue, setnavValue] = useState(() => getLocalStorage('chimba-mnulvl', initialState));
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const toggleNav = (q) => setnavValue(q);

  useEffect(() => {
    setLocalStorage('chimba-mnulvl', navValue);
  }, [navValue]);

  return (
    <NavContext.Provider value={navValue}>
      <NavUpdateContext.Provider value={toggleNav}>{children}</NavUpdateContext.Provider>
    </NavContext.Provider>
  );
}

NavProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
