export const config = {
  SOFT_LOGOUT: 'soft_logout',
  FORCE_LOGOUT: 'force_logout',
  LIST_USER: 'list_user',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SOCKET_CONNECT: 'socket_connect',
  SOCKET_DISCONNECT: 'socket_disconnect',
  SOCKET_CONNECT_ERROR: 'socket_connect_error',
  SOCKET_CONNECT_SUCCESS: 'socket_connect_success',
};
