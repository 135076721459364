import React from 'react';
import PropTypes from 'prop-types';

export function MenuIconDefault({ width = '24px', height = '24px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#7B7D8B"
      />
    </svg>
  );
}

MenuIconDefault.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

MenuIconDefault.defaultProps = {
  width: '24px',
  height: '24px',
};
