import React, { useEffect } from 'react';
import DashboardLayout from '../../../layout/DashboardLayout';

export default function PageNotFound() {
  useEffect(() => {
    document.title = 'Not Found - Nadi Admin';
  }, []);
  return (
    <DashboardLayout>
      <div className="flex h-screen">
        <div className="mx-auto my-52">
          <strong className="text-3xl">Oops, Sorry page not found</strong>
        </div>
      </div>
    </DashboardLayout>
  );
}
