/* eslint-disable func-names */
/* eslint-disable no-console */
import React from 'react';
import { useAuthStore } from '../../auth/stores';
import Page403 from '../pages/Page403';

// eslint-disable-next-line func-names
const withRole = (roles) => (Component, onDeny) =>
  function (props) {
    const [state] = useAuthStore();
    let userRoles = [];
    if (state.data.roles && state.data.roles.length > 0) {
      userRoles = state.data.roles[0].code;
    } else {
      console.warn('user role nil, this step should never be triggered');
    }

    let hasAccess = false;
    if (roles.length > 0) {
      hasAccess = userRoles === 'SUPERADMIN' || roles.includes(userRoles);
      // hasAccess = userRoles.includes['SUPERADMIN'] || userRoles.some((q) => roles.includes(q));
    } else {
      hasAccess = true;
    }

    if (hasAccess) return React.createElement(Component, props, null);
    if (onDeny) return React.createElement(onDeny, props, null);

    return <Page403 />;
  };

export default withRole;
