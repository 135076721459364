import { createStore, createHook } from 'react-sweet-state';
import { deletePatientDiagnose, listPatientDiagnose, addMedicalHistory } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: false,
    data: [],
    error: false,
    limit: 5,
    skip: 0,
    search: '',
    startDate: '',
    endDate: '',
    sort: 'desc',
    startDate: '',
    endDate: '',
    total: 0,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    resetStore:
      () =>
      async ({ setState }) => {
        setState({
          isLoading: false,
          data: [],
          error: false,
          limit: 5,
          skip: 0,
          search: '',
          startDate: '',
          endDate: '',
          sort: 'desc',
          startDate: '',
          endDate: '',
          total: 0,
          activePage: 0,
        });
      },
    loadData:
      (customerId) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });

        try {
          const res = await listPatientDiagnose(getState(), customerId);

          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.medicals,
              isLoading: false,
              total: res.data.payload.total,
            });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
    deleteRecord:
      (id) =>
      async ({ setState }) => {
        try {
          // console.log('ini id to delete', id);
          const res = await deletePatientDiagnose(id);
          if (res.data.code === 200) {
            setState({ isLoading: false });
            return true;
          }
          throw new Error('Delete Patient Diagnosa Failed');
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return false;
        }
      },
    addRecord:
      (body) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          // console.log('ini body', body);
          await addMedicalHistory(body);
          setState({ isLoading: false });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'patientDiagnoseAppointmentDoctor',
});

export const usePatientDiagnoseStore = createHook(Store);
