import axios from 'axios';
import { io } from 'socket.io-client';
import { config } from './socketConfig';

const baseApi = process.env.REACT_APP_BASE_API;

export const socket = io.connect(baseApi);
export const socketConfig = config;

axios.defaults.withCredentials = true;
// code di bawah ini jangan di rubah. jika ingin merubah, harus konfirmasi ke tim Global
const instance = axios.create({
  baseURL: baseApi,
  // xsrfCookieName: 'XSRF-TOKEN',
  // xsrfHeaderName: 'X-XSRF-TOKEN',
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  },
});

export const apiClient = instance;
