import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/index.css';
import { useAuthStore } from '../stores';
import { Alert } from '../../commons/components';

export function FormLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [authState, authActions] = useAuthStore();

  // let popup;
  // function openPopupOneAtATime() {
  //   if (popup && !popup.closed) {
  //     popup.location.reload('/#/e-clinic/chat');
  //     popup.focus();
  //     /* or do something else, e.g. close the popup or alert a warning */
  //   } else {
  //     popup = window.open('/#/e-clinic/chat', 'full', 'width=1024,height=700,status=0');
  //   }
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await authActions.login({ email, password });
    // openPopupOneAtATime();
    // setTimeout(async () => {
    //   console.log('authstate', authState.data);
    //   window.open('/#/e-clinic/chat', 'full', 'width=1024,height=700,status=0');
    // }, 1500);
  };

  let errorUi;

  if (authState.error) {
    // console.log('errora1', authState.error);
    const { message } = authState.error.error;
    errorUi = (
      <div className="space-y-1">
        <Alert type="error" content={message} />
      </div>
    );
  }

  if (authState.block) {
    // eslint-disable-next-line no-console
    console.log('👮 Login Block');
    errorUi = (
      <div className="space-y-1">
        <Alert type="warning" content="Please logout on another device first" />
      </div>
    );
  }

  return (
    <div className="mt-8">
      <div className="mt-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="example@example.com"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>

          <div className="space-y-1">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>
          {errorUi}

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to="/forgot-password" className="text-sm">
                Forgot your Password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn_primary ml-auto uppercase"
              data-dashlane-rid="fd66152da8a85f08"
              data-dashlane-label="true"
              data-form-type="action,login"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
