import moment from 'moment';
import React from 'react';

const myDoctorSVG = (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2002 9.99271L7.99444 10.2809L8.78868 9.99271L9.83021 9.64062H9.83676C10.0005 9.657 12.0639 9.88627 13.3462 11.5517C14.6808 13.286 15.6388 15.6654 15.5111 17.4619C15.4407 18.4788 14.3082 19.1224 14.0134 19.4893H2.34193C2.04715 19.1224 0.543281 18.4788 0.479414 17.4619C0.356593 15.6605 1.30805 13.286 2.64434 11.5517C3.9266 9.88627 5.98836 9.657 6.15212 9.64062H6.16358L7.2002 9.99271Z"
      fill="#28DAD1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88281 10.2422L7.99908 10.2848L8.11535 10.2422V19.4931H7.99908H7.88281V10.2422Z"
      fill="#24C4BC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.13083 19.4932H2.16625C1.31248 19.4932 0.543281 18.4828 0.479414 17.4658C0.356593 15.6644 1.30805 13.2899 2.64434 11.5556C3.9266 9.89017 5.98836 9.66091 6.15212 9.64453H6.16359L6.37811 9.71658C6.27167 11.8357 6.16359 17.6689 6.13083 19.4932ZM9.8646 19.4932H13.7994C14.8425 19.4932 15.4522 18.4828 15.5177 17.4658C15.6405 15.6644 14.6874 13.2899 13.3527 11.5556C12.0705 9.89017 10.0038 9.66091 9.84004 9.64453L9.62551 9.71658C9.72377 11.8357 9.84004 17.6689 9.8646 19.4932Z"
      fill="#DCEAEB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4617 19.4897H12.7531C12.8006 18.7037 12.9267 16.9776 13.1609 16.3586C13.4688 15.5398 12.1865 12.2891 12.1783 12.3808C12.1702 12.4725 12.3536 14.3312 12.3994 15.7445C12.4322 16.727 12.4567 18.5104 12.4682 19.4897H12.4617ZM3.54974 19.4897H3.25333C3.20584 18.7037 3.07811 16.9776 2.84393 16.3586C2.53769 15.5398 3.8265 12.2891 3.8265 12.3808C3.8265 12.4725 3.64964 14.3312 3.60378 15.7445C3.58086 16.732 3.55465 18.5104 3.54319 19.4897H3.54974Z"
      fill="#C6D3D4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.07192 9.65045L6.15216 9.64062H6.16362L6.37815 9.71268C6.29955 11.2864 6.21439 14.9088 6.17017 17.4095C5.7231 16.4564 4.56695 13.9819 4.5473 13.8067C4.52273 13.5856 5.58063 12.4131 5.58063 12.4131C5.58063 12.4131 5.09754 11.5763 5.09754 11.4224C5.09754 11.3126 5.72638 10.2384 6.08011 9.65209L6.07192 9.65045ZM9.92523 9.65045L9.84335 9.64062H9.83844L9.62391 9.71268C9.70251 11.2864 9.78767 14.9088 9.83189 17.4095C10.279 16.4564 11.4351 13.9819 11.4531 13.8067C11.4761 13.584 10.4247 12.4098 10.4247 12.4098C10.4247 12.4098 10.916 11.573 10.916 11.4191C10.9029 11.311 10.2724 10.2367 9.92359 9.65045H9.92523Z"
      fill="#F1F7F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58435 12.311C4.58435 12.2897 4.58435 12.2668 4.58435 12.2439C4.61219 11.0664 4.95609 9.71701 6.19249 9.26994C7.10463 8.95996 8.08379 8.90336 9.02557 9.10618C9.71501 9.25684 10.5404 9.59747 10.8892 10.2525C10.973 10.4113 11.0463 10.5754 11.1086 10.7438C11.3008 11.3352 11.383 11.9569 11.351 12.5779C11.351 13.0397 11.3166 13.5015 11.2855 13.9617C11.2429 14.5971 11.1889 15.2341 11.1381 15.8695C11.1168 16.1365 11.0939 16.405 11.0726 16.6736C11.072 16.7127 11.0863 16.7505 11.1126 16.7795C11.1389 16.8084 11.1752 16.8262 11.2142 16.8293C11.2532 16.8325 11.2919 16.8206 11.3224 16.7963C11.353 16.7719 11.3732 16.7369 11.3788 16.6982C11.4001 16.4296 11.4214 16.161 11.4443 15.8925C11.4951 15.2538 11.5491 14.6168 11.5917 13.9781C11.6245 13.513 11.6523 13.0447 11.6572 12.5779C11.688 11.9536 11.6104 11.3286 11.428 10.7307C11.3556 10.5218 11.2663 10.3192 11.161 10.1248C10.768 9.38294 9.87386 8.97845 9.09107 8.81469C8.09448 8.59902 7.058 8.65846 6.0926 8.98664C4.71536 9.47792 4.31251 10.9207 4.28139 12.2422C4.28139 12.2635 4.28139 12.2832 4.28139 12.3028C4.27965 12.3432 4.29403 12.3826 4.32137 12.4124C4.3487 12.4422 4.38675 12.4599 4.42714 12.4617C4.46753 12.4634 4.50696 12.449 4.53675 12.4217C4.56654 12.3944 4.58425 12.3563 4.58599 12.3159L4.58435 12.311Z"
      fill="#21485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2212 17.9306C11.4673 17.9306 11.7079 17.8576 11.9126 17.7208C12.1173 17.5841 12.2768 17.3897 12.371 17.1623C12.4652 16.9349 12.4898 16.6846 12.4418 16.4432C12.3938 16.2018 12.2753 15.98 12.1012 15.8059C11.9271 15.6319 11.7054 15.5133 11.464 15.4653C11.2225 15.4173 10.9723 15.4419 10.7449 15.5361C10.5174 15.6303 10.3231 15.7899 10.1863 15.9945C10.0496 16.1992 9.97656 16.4398 9.97656 16.686C9.977 17.016 10.1083 17.3323 10.3416 17.5656C10.5749 17.7989 10.8912 17.9302 11.2212 17.9306V17.9306Z"
      fill="#FFD8A2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2219 17.4673C11.3771 17.4673 11.5287 17.4213 11.6577 17.3351C11.7867 17.2489 11.8873 17.1264 11.9466 16.983C12.006 16.8397 12.0215 16.682 11.9913 16.5298C11.961 16.3777 11.8863 16.2379 11.7766 16.1282C11.6669 16.0185 11.5271 15.9438 11.3749 15.9135C11.2228 15.8832 11.0651 15.8988 10.9217 15.9581C10.7784 16.0175 10.6559 16.1181 10.5697 16.2471C10.4835 16.3761 10.4375 16.5277 10.4375 16.6829C10.4375 16.8909 10.5201 17.0904 10.6673 17.2375C10.8144 17.3846 11.0139 17.4673 11.2219 17.4673V17.4673Z"
      fill="#FFA11D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2243 17.1094C11.3084 17.109 11.3906 17.0838 11.4604 17.0368C11.5302 16.9898 11.5845 16.9232 11.6165 16.8454C11.6485 16.7676 11.6567 16.682 11.6401 16.5995C11.6234 16.517 11.5828 16.4413 11.5232 16.3819C11.4635 16.3226 11.3877 16.2822 11.3051 16.2659C11.2226 16.2496 11.137 16.2581 11.0593 16.2904C10.9816 16.3227 10.9152 16.3772 10.8685 16.4472C10.8218 16.5172 10.7969 16.5994 10.7969 16.6836C10.7969 16.7396 10.8079 16.7951 10.8294 16.8469C10.8509 16.8987 10.8825 16.9457 10.9222 16.9852C10.9619 17.0248 11.009 17.0561 11.0609 17.0774C11.1127 17.0987 11.1682 17.1096 11.2243 17.1094V17.1094Z"
      fill="#FF6D11"
    />
    <path d="M9.12382 7.74609H6.86719V10.2811H9.12382V7.74609Z" fill="#EEBCAA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86719 8.16797H9.12382V9.17019C8.76522 9.31989 8.37937 9.39327 7.99085 9.38566C7.60233 9.37805 7.21965 9.28961 6.86719 9.12598V8.16797Z"
      fill="#E2977C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.87001 9.20312C7.12812 9.66884 7.52159 10.0452 7.99833 10.2823C7.99833 10.2823 9.05459 11.0389 9.28877 11.0389C9.52295 11.0389 9.82264 9.81396 9.8341 9.64201C9.84556 9.47006 9.12665 9.20312 9.12665 9.20312C8.86853 9.66884 8.47506 10.0452 7.99833 10.2823C7.99833 10.2823 6.9437 11.0389 6.70952 11.0389C6.47534 11.0389 6.17566 9.81396 6.1642 9.64201C6.15274 9.47006 6.87001 9.20312 6.87001 9.20312Z"
      fill="#A9F0ED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47714 7.33692C4.49456 4.97056 4.86958 2.04414 7.86478 1.74609L7.99907 1.77721C8.04164 1.76575 8.0875 1.75592 8.13499 1.74609C11.1302 2.04414 11.5068 4.97056 10.5226 7.33692C10.3249 7.84517 9.97822 8.28184 9.52806 8.58972C9.07791 8.8976 8.54526 9.06233 7.99989 9.06233C7.45452 9.06233 6.92186 8.8976 6.47171 8.58972C6.02155 8.28184 5.67489 7.84517 5.47714 7.33692V7.33692Z"
      fill="#F4C6BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.02807 5.65447C4.73002 5.52182 4.28787 5.4121 4.01602 5.76255C3.60007 6.29968 4.24856 7.62779 5.52263 7.44929C5.50625 7.4149 5.49151 7.38051 5.47678 7.34448C5.25012 6.80449 5.09912 6.23578 5.02807 5.65447V5.65447ZM10.971 5.65447C11.269 5.52182 11.7112 5.4121 11.983 5.76255C12.399 6.29968 11.7505 7.62779 10.4764 7.44929C10.4928 7.4149 10.5075 7.38051 10.5223 7.34448C10.7489 6.80449 10.8999 6.23578 10.971 5.65447Z"
      fill="#EEBCAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63081 6.75391C6.83224 6.75391 6.996 6.80958 6.996 6.87836C6.996 6.94714 6.83224 7.00282 6.63081 7.00282C6.42939 7.00282 6.26562 6.94714 6.26562 6.87836C6.26562 6.80958 6.42939 6.75391 6.63081 6.75391ZM9.36072 6.75391C9.15929 6.75391 8.99553 6.80958 8.99553 6.87836C8.99553 6.94714 9.15929 7.00282 9.36072 7.00282C9.56215 7.00282 9.72591 6.94714 9.72591 6.87836C9.72591 6.80958 9.56215 6.75391 9.36072 6.75391Z"
      fill="#FFE8E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.26355 5.46875C9.13909 5.46875 9.03919 5.63251 9.03919 5.82903C9.03919 6.02554 9.13909 6.19094 9.26355 6.19094C9.38801 6.19094 9.48626 6.02718 9.48626 5.82903C9.48626 5.63087 9.38637 5.46875 9.26355 5.46875ZM6.73998 5.46875C6.8628 5.46875 6.9627 5.63251 6.9627 5.82903C6.9627 6.02554 6.8628 6.19094 6.73998 6.19094C6.61716 6.19094 6.51562 6.02718 6.51562 5.82903C6.51562 5.63087 6.61716 5.46875 6.73998 5.46875V5.46875Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.94344 4.68563C9.99084 4.703 10.0295 4.73837 10.0509 4.78406C10.0724 4.82975 10.075 4.88207 10.0581 4.92964C10.0497 4.95324 10.0366 4.97494 10.0198 4.99349C10.003 5.01205 9.98261 5.02709 9.95993 5.03774C9.93725 5.0484 9.91269 5.05446 9.88765 5.05558C9.86262 5.0567 9.83761 5.05286 9.81407 5.04427C9.64606 5.0018 9.47312 4.98198 9.29986 4.98532C9.1489 4.97645 8.99748 4.99137 8.85115 5.02953C8.82792 5.03889 8.80308 5.04358 8.77805 5.04333C8.75301 5.04309 8.72826 5.03791 8.70523 5.0281C8.68219 5.01829 8.66131 5.00404 8.64377 4.98617C8.62624 4.96829 8.61241 4.94713 8.60305 4.92391C8.5937 4.90068 8.58901 4.87584 8.58925 4.8508C8.5895 4.82576 8.59467 4.80102 8.60448 4.77798C8.61429 4.75494 8.62854 4.73406 8.64642 4.71653C8.6643 4.699 8.68545 4.68516 8.70868 4.67581C8.90128 4.61811 9.10232 4.59374 9.30313 4.60375C9.51934 4.60114 9.73485 4.6287 9.94344 4.68563V4.68563ZM6.17692 5.04427C6.1534 5.05308 6.12835 5.0571 6.10325 5.05609C6.07815 5.05508 6.0535 5.04905 6.03076 5.03837C6.00803 5.02769 5.98765 5.01257 5.97085 4.99389C5.95405 4.97522 5.94115 4.95337 5.93292 4.92964C5.91603 4.88207 5.91859 4.82975 5.94006 4.78406C5.96152 4.73837 6.00015 4.703 6.04755 4.68563C6.25614 4.6287 6.47165 4.60114 6.68786 4.60375C6.88866 4.59387 7.08968 4.61824 7.28231 4.67581C7.30554 4.68516 7.3267 4.699 7.34457 4.71653C7.36245 4.73406 7.3767 4.75494 7.38651 4.77798C7.39632 4.80102 7.40149 4.82576 7.40174 4.8508C7.40198 4.87584 7.39729 4.90068 7.38794 4.92391C7.37858 4.94713 7.36475 4.96829 7.34722 4.98617C7.32968 5.00404 7.3088 5.01829 7.28577 5.0281C7.26273 5.03791 7.23798 5.04309 7.21294 5.04333C7.18791 5.04358 7.16307 5.03889 7.13984 5.02953C6.99351 4.99137 6.84209 4.97645 6.69113 4.98532C6.51896 4.98227 6.34715 5.00209 6.1802 5.04427H6.17692Z"
      fill="#21485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.3604 7.52343C7.34926 7.51304 7.3406 7.50027 7.33506 7.48607C7.32952 7.47187 7.32726 7.4566 7.32842 7.44141C7.32959 7.42621 7.33417 7.41147 7.34181 7.39828C7.34945 7.3851 7.35996 7.3738 7.37257 7.36523C7.38517 7.35666 7.39954 7.35104 7.41461 7.34878C7.42969 7.34652 7.44507 7.34768 7.45963 7.35218C7.4742 7.35668 7.48756 7.3644 7.49873 7.37477C7.5099 7.38514 7.51859 7.39789 7.52416 7.41208C7.57846 7.49177 7.65634 7.55247 7.74688 7.58566C7.86014 7.62849 7.98172 7.64473 8.10224 7.63315V7.63315C8.21239 7.6249 8.31902 7.59066 8.41339 7.53326C8.45902 7.50616 8.49508 7.46552 8.51656 7.41699C8.52159 7.40461 8.52902 7.39334 8.53842 7.38384C8.54781 7.37433 8.559 7.36678 8.57132 7.3616C8.58365 7.35643 8.59687 7.35374 8.61024 7.35369C8.6236 7.35364 8.63684 7.35623 8.64921 7.36131C8.66167 7.36601 8.67308 7.37316 8.68274 7.38234C8.6924 7.39151 8.70013 7.40253 8.70547 7.41474C8.71081 7.42695 8.71366 7.4401 8.71384 7.45342C8.71402 7.46675 8.71153 7.47997 8.70652 7.49232C8.66877 7.57955 8.60537 7.65323 8.52475 7.70357C8.40272 7.77979 8.2638 7.82479 8.12026 7.83458V7.83458C7.96746 7.85073 7.81301 7.82995 7.66991 7.77399C7.54394 7.72342 7.43609 7.63612 7.3604 7.52343Z"
      fill="#21485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83259 6.95487C7.81362 6.94817 7.79802 6.9343 7.78914 6.91624C7.78027 6.89818 7.77882 6.87737 7.7851 6.85825C7.79214 6.83915 7.80632 6.82353 7.82465 6.81467C7.84297 6.80582 7.86402 6.80441 7.88336 6.81076C7.93963 6.83061 7.99891 6.84059 8.05858 6.84024V6.84024C8.11487 6.84098 8.17068 6.82981 8.22235 6.80748C8.23163 6.80203 8.24194 6.79858 8.25264 6.79737C8.26333 6.79615 8.27416 6.79719 8.28443 6.80042C8.2947 6.80365 8.30417 6.809 8.31224 6.81611C8.32032 6.82323 8.32681 6.83196 8.33129 6.84175C8.33578 6.85153 8.33817 6.86214 8.3383 6.8729C8.33843 6.88367 8.3363 6.89434 8.33206 6.90422C8.32781 6.91411 8.32153 6.923 8.31364 6.93031C8.30574 6.93763 8.2964 6.9432 8.28621 6.94668C8.21699 6.97686 8.14229 6.99247 8.06677 6.99253C7.98704 6.99479 7.9076 6.98201 7.83259 6.95487V6.95487Z"
      fill="#E2977C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.71068 3.59324C5.68931 4.0222 5.57771 4.44183 5.38316 4.82473C5.05564 5.44211 5.03271 5.65336 5.03271 5.65336C4.856 5.56846 4.66214 5.52532 4.4661 5.52727C4.34647 5.14245 4.29982 4.73867 4.32853 4.33672C4.40386 3.59324 4.90989 3.42457 4.90989 3.42457C4.85142 3.16068 4.85531 2.8868 4.92127 2.62469C4.98722 2.36257 5.11342 2.11946 5.28982 1.91468C5.89901 1.18922 7.25496 0.932112 8.71735 0.943575C10.1797 0.955039 10.355 0.452291 10.581 0.512882C10.8069 0.573474 10.9183 1.01891 10.9183 1.01891C11.145 1.13892 11.3315 1.32285 11.4547 1.54788C11.5778 1.77291 11.6322 2.02914 11.611 2.28478C11.5684 3.2215 11.0968 3.43112 11.0968 3.43112C11.0968 3.43112 11.6028 3.59488 11.6798 4.34327C11.7089 4.74535 11.6617 5.14929 11.5406 5.53382C11.3451 5.53182 11.1517 5.57498 10.9756 5.65991C10.9756 5.65991 10.9461 5.44866 10.6252 4.83128C10.4383 4.46291 10.327 4.06092 10.2977 3.64892C10.1503 3.66696 10.0051 3.69876 9.86368 3.7439C9.43299 3.88637 7.93948 4.28268 6.74238 3.82742C5.54528 3.37216 5.71068 3.59324 5.71068 3.59324Z"
      fill="#21485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.71068 3.59319C5.68931 4.02215 5.57771 4.44178 5.38316 4.82468C5.05564 5.44206 5.03271 5.65332 5.03271 5.65332C4.856 5.56841 4.66214 5.52527 4.46609 5.52722C4.34647 5.1424 4.29982 4.73862 4.32853 4.33667C4.40386 3.59319 4.90989 3.42452 4.90989 3.42452C5.18249 3.39914 5.45688 3.4478 5.70413 3.56535C5.70365 3.57529 5.70654 3.5851 5.71232 3.59319H5.71068ZM11.0952 3.42943C11.0952 3.42943 11.6012 3.59319 11.6782 4.34158C11.7072 4.74366 11.66 5.14761 11.539 5.53213C11.3434 5.53014 11.1501 5.57329 10.974 5.65823C10.974 5.65823 10.9445 5.44697 10.6235 4.82959C10.4367 4.46123 10.3253 4.05924 10.296 3.64723C10.296 3.64723 10.8119 3.40814 11.0952 3.42452V3.42943Z"
      fill="#183544"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28821 6.82877C5.23253 6.66501 5.18504 6.48651 5.14573 6.31456C5.00316 6.15155 4.80305 6.05003 4.58731 6.03125C4.18282 6.03125 4.43992 6.57166 4.43992 6.57166C4.65117 6.6732 4.46612 6.22449 4.70521 6.32602C4.94431 6.42755 5.28821 6.82877 5.28821 6.82877V6.82877ZM10.7136 6.82877C10.7677 6.66501 10.8152 6.48651 10.8561 6.31456C10.998 6.15151 11.1976 6.04994 11.4129 6.03125C11.819 6.03125 11.5619 6.57166 11.5619 6.57166C11.3507 6.6732 11.5357 6.22449 11.295 6.32602C11.0543 6.42755 10.7136 6.82877 10.7136 6.82877Z"
      fill="#E2977C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.57909 16.3741C3.63757 16.3768 3.6929 16.4014 3.734 16.4431C3.77511 16.4848 3.79898 16.5405 3.80084 16.599C3.8027 16.6575 3.78242 16.7146 3.74404 16.7588C3.70567 16.803 3.65202 16.8312 3.59383 16.8376C3.44145 16.852 3.2878 16.8307 3.14512 16.7753C3.04421 16.7296 2.95919 16.6549 2.90087 16.5607C2.84255 16.4665 2.81356 16.3571 2.81759 16.2464C2.80613 16.0564 2.81759 15.4112 2.81759 14.7611C2.81759 14.1109 2.85035 13.4067 2.86836 13.2217C2.8846 12.9689 2.97903 12.7274 3.13857 12.5306C3.32526 12.3128 3.62985 12.1425 4.12114 12.1114C4.35568 12.0983 4.59077 12.0983 4.82531 12.1114C5.3166 12.1425 5.61792 12.3128 5.80788 12.5306C5.9677 12.7271 6.06166 12.9689 6.07645 13.2217C6.09447 13.4067 6.11576 14.088 6.12886 14.7611C6.14196 15.4341 6.14523 16.0564 6.12886 16.2464C6.13245 16.357 6.10329 16.4662 6.04502 16.5603C5.98675 16.6544 5.90197 16.7292 5.80133 16.7753C5.65865 16.8307 5.505 16.852 5.35263 16.8376C5.29443 16.8312 5.24079 16.803 5.20241 16.7588C5.16403 16.7146 5.14375 16.6575 5.14561 16.599C5.14747 16.5405 5.17134 16.4848 5.21245 16.4431C5.25356 16.4014 5.30888 16.3768 5.36736 16.3741C5.44931 16.383 5.5322 16.3758 5.61137 16.3528C5.64085 16.3397 5.66377 16.3037 5.66869 16.2185C5.67851 16.0351 5.66869 15.3997 5.66869 14.7692C5.65722 14.1142 5.63757 13.4411 5.61956 13.2659C5.61048 13.1079 5.55334 12.9565 5.4558 12.8319C5.3428 12.6993 5.14465 12.5961 4.80075 12.5732C4.58256 12.5615 4.36389 12.5615 4.1457 12.5732C3.8018 12.5961 3.60365 12.6993 3.49066 12.8319C3.39311 12.9565 3.33598 13.1079 3.32689 13.2659C3.30888 13.4411 3.28923 14.1076 3.27777 14.7692C3.26467 15.4046 3.25975 16.0351 3.27777 16.2185C3.27777 16.3037 3.30561 16.3397 3.33508 16.3528C3.41425 16.3758 3.49714 16.383 3.57909 16.3741V16.3741Z"
      fill="#FFA11D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.58218 16.3741C3.64066 16.3768 3.69598 16.4015 3.73709 16.4432C3.7782 16.4849 3.80207 16.5405 3.80393 16.599C3.80579 16.6576 3.78551 16.7146 3.74713 16.7589C3.70876 16.8031 3.65511 16.8312 3.59691 16.8376C3.44454 16.8521 3.29089 16.8308 3.14821 16.7754C3.0473 16.7296 2.96228 16.6549 2.90396 16.5607C2.84564 16.4665 2.81665 16.3571 2.82068 16.2464C2.82068 16.2006 2.82068 16.1269 2.82068 16.0352H3.28249C3.28249 16.1154 3.28249 16.1776 3.28249 16.2186C3.28249 16.3037 3.31033 16.3398 3.33981 16.3529C3.41898 16.3758 3.50187 16.383 3.58381 16.3741H3.58218ZM6.1385 16.0352C6.1385 16.1269 6.1385 16.1989 6.1385 16.2464C6.14209 16.357 6.11293 16.4663 6.05465 16.5604C5.99638 16.6545 5.91161 16.7293 5.81097 16.7754C5.66829 16.8308 5.51464 16.8521 5.36227 16.8376C5.30407 16.8312 5.25042 16.8031 5.21205 16.7589C5.17367 16.7146 5.15339 16.6576 5.15525 16.599C5.15711 16.5405 5.18098 16.4849 5.22209 16.4432C5.2632 16.4015 5.31852 16.3768 5.377 16.3741C5.45895 16.383 5.54184 16.3758 5.62101 16.3529C5.65049 16.3398 5.67341 16.3037 5.67833 16.2186C5.67833 16.1776 5.67833 16.1154 5.67833 16.0352H6.1385Z"
      fill="#FF6D11"
    />
  </svg>
);

export default function ListNotificationMyPharma({ item, handleLink }) {
  // console.log(item, 'ListNotificationMyPharma item');
  return (
    <>
      <a
        key={item.name}
        href={handleLink(item)}
        className={`flex items-center w-full ${
          item.isRead ? '' : 'mypharma'
        } bordered border p-2 -m-3 transition duration-150 ease-in-out rounded-lg cursor-pointer focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
      >
        <div className="indicator ml-4 w-full">
          {/* <span className="indicator-item badge badge-primary" /> */}
          <div className="p-4 w-full hover:bg-base-200 place-items-center rounded-lg">
            <div className="flex justify-between mb-1">
              <div className="flex row">
                {myDoctorSVG}
                <p className="text-sm mx-2 custom-notification-title">
                  {item.module} - {item.title}
                </p>
                {item.isRead ? (
                  ''
                ) : (
                  <svg
                    className="mt-1"
                    width="8"
                    height="9"
                    viewBox="0 0 8 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4" cy="4.54688" r="4" fill="#FF5947" />
                  </svg>
                )}
              </div>
              <p className="text-xs text-gray-500">
                {moment(item.dateTime).format('hh:mm A DD MMM YYYY')}
              </p>
            </div>
            <p className="text-sm custom-notification-description mt-1">{item.message}</p>
            {/* <p className="text-sm custom-notification-order">
              Order No: <span className="custom-notification-order__span">839202021</span>
            </p> */}
          </div>
        </div>
      </a>
    </>
  );
}
