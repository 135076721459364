/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Header, Footer } from '../commons/components';
// import DashboardLayout from '../../../../commons/layout/DashboardLayout';
import DashboardLayout from '../commons/layout/DashboardLayout';
import { ListHeader, ListTable, ListPaging } from './components';
import { useListNotifStore } from './stores';
// import { usePageSettingHeaderStore } from '../commons/stores';
import { usePageSettingHeaderStore } from '../commons/stores';

function PageTitle({ title }) {
  return (
    <>
      <div className="flex justify-center items-center mb-2">
        <h1 className="flex-1 text-2xl font-bold lg:text-2xl" style={{ color: '#3B4F5B' }}>
          {title}
        </h1>
      </div>
    </>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default function Home() {
  const [state, actions] = useListNotifStore();
  const [statePageSettingHeader] = usePageSettingHeaderStore();
  useEffect(() => {
    document.title = 'Notification List - Nadi Admin';
    actions.loadData();
  }, []);

  let contentUi = <div className="loader-block" />;
  // if (!state.isLoading) {
  contentUi = (
    <React.Fragment>
      <ListTable data={state.data} />
      <ListPaging />
    </React.Fragment>
  );
  // }

  return (
    <DashboardLayout>
      <section className="content-center -mt-2 mx-10">
        <div className="flex">
          <PageTitle title="List Of Notifications" />
        </div>
        {/* <div className="flex text-sm font-normal" style={{ color: '#9DA7AD' }}>
          <p>Activities you need to do to maintain buyer satisfaction</p>
        </div> */}
      </section>
      {contentUi}
    </DashboardLayout>
  );
}
