import { createStore, createHook } from 'react-sweet-state';
import { listPatientDiagnose } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: false,
    data: [],
    error: false,
    limit: 10,
    skip: 0,
    search: '',
    startDate: '',
    endDate: '',
    sort: 'desc',
    startDate: '',
    endDate: '',
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadData:
      (customerId) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });

        try {
          const res = await listPatientDiagnose(getState(), customerId);

          if (res.data && res.data.code === 200) {
            setState({ data: res.data.payload.medicals, isLoading: false });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
  },
  name: 'patientDiagnoseRequestDoctor',
});

export const usePatientDiagnoseStore = createHook(Store);
