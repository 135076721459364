import SDK from '@livechat/chat-sdk';

// Initialize ChatSDK instance
export const ChatSDK = new SDK({
  debug: true,
});

// Custom methods created with `ChatSDK.methodFactory`:

/**
 * Init Live Chat
 * Base on: https://developers.livechat.com/docs/messaging/chat-sdk#init
 */
export const initChat = (pat) =>
  ChatSDK.init({
    personal_access_token: pat
  });

/**
 * Logout
 */
export const logOut = () =>
  ChatSDK.methodFactory({
    action: 'logout',
    payload: {},
  });

/**
 * Set Away Status
 */
export const awayStatus = () =>
  ChatSDK.methodFactory({
    action: 'set_away_status',
    payload: {
      away: true
    },
  });

/**
 * Returns list of last 10 archived chats
 * Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference/#get-archives
 */
export const getArchives = () =>
  ChatSDK.methodFactory({
    action: 'list_archives',
    payload: {
      pagination: {
        limit: 10,
      },
    },
  });

/**
 * Returns threads that the current Agent has access to in a given chat.
 * @param {string} chat_id
 * @param {string[]} thread_ids
 * Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference/#get-chat-threads
 */
export const getChat = (chat_id, thread_id) =>
  ChatSDK.methodFactory({
    action: 'get_chat',
    payload: { chat_id, thread_id },
  });

/**
 * Returns threads that the current Agent has access to in a given chat.
 * @param {string} chat_id
 * @param {string[]} thread_ids
 * Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference/#get-chat-threads
 */
export const getChatsList = async () => {
  ChatSDK.methodFactory({
    action: 'list_chats',
    payload: {},
  });
};

/**
 * Move chat from queue by add user
 * @param {string} chat_id
 * @param {string} user_id
 * @param {string} user_type
 * Based on: https://developers.livechat.com/docs/messaging/agent-chat-api/rtm-reference#add-user-to-chat
 */
// export const addUserToChat = (chat_id, user_id, user_type) =>
//   ChatSDK.methodFactory({
//     action: "add_user_to_chat",
//     payload: { chat_id, user_id, user_type }
//   });
