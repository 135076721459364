/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import img from '../img/logo.png';

export function Header({ mode }) {
  let LinkUi;

  if (mode === 'forgot-password') {
    LinkUi = (
      <Link to="/auth" className="btn btn_primary uppercase ml-5">
        Login
      </Link>
    );
  }
  return (
    <section className="navbar mb-2 shadow-md text-neutral-content">
      <div className="flex-1 px-2 mx-2 ml-6">
        <img alt="nadi" src={img} style={{ width: 100 }} />
      </div>
      <nav className="flex items-center ml-auto">{LinkUi}</nav>
    </section>
  );
}
