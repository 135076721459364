import { apiClient } from '../../commons/services';

const groupPath = '/v1/admin/notification';

export const listApi = ({ limit, skip, search, orderBy, orderByDir, isRead }) =>
  apiClient.get(
    `${groupPath}/list-notification?limit=${limit}&skip=${skip}&search=${search}&orderBy=${orderBy}&orderByDir=${orderByDir}&isRead=${isRead}`,
  );

export const updateReadNotificationById = (id) => {
  apiClient.post(`${groupPath}/update-is-read/${id}`);
};
