/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getTokens } from '../../firebaseNotifToken';

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  // console.log('Token found', isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getTokens(setTokenFound);
      if (data) {
        const baseApi = process.env.REACT_APP_BASE_API;
        await axios.post(
          `${baseApi}/v1/admin/auth/push-notification-token-web`,
          {
            token: data,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            },
          },
        );
        console.log('Token is', data);
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
