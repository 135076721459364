/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useResetStore } from '../stores';
import '../css/index.css';

function EyesIcon() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Iconly/Curved/Show">
          <g id="Show">
            <path
              id="Stroke 1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1638 12.0516C15.1638 13.7976 13.7478 15.2136 12.0018 15.2136C10.2558 15.2136 8.83984 13.7976 8.83984 12.0516C8.83984 10.3046 10.2558 8.88965 12.0018 8.88965C13.7478 8.88965 15.1638 10.3046 15.1638 12.0516Z"
              stroke="#7B7D8B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Stroke 3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.75 12.052C2.75 15.332 6.892 19.354 12.002 19.354C17.111 19.354 21.254 15.335 21.254 12.052C21.254 8.769 17.111 4.75 12.002 4.75C6.892 4.75 2.75 8.772 2.75 12.052Z"
              stroke="#7B7D8B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </>
  );
}

export function FormBoxResetPassword() {
  const [resetState, resetActions] = useResetStore();
  const [showPassword, setshowPassword] = useState(false);

  const { _id } = useParams();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  useEffect(async () => {
    document.title = 'Set New Password - Nadi Admin';
    resetActions.checkResetPass({ _id });
  }, []);

  const toggleShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-alert
    // eslint-disable-next-line no-shadow
    const { password } = data;
    try {
      await resetActions.reset({ password, _id });
      if (resetState.resetSuccess) {
        toast.success('Reset password success');
        history.push('/');
      }
    } catch (err) {
      console.log(err);
      toast.error('Fail to reset password');
    }
  };

  return (
    <div className="flex justify-center items-center p-4">
      {!resetState.resetNotValid ? (
        <>
          <div className="flex-wrap w-screen h-auto justify-center items-center text-center">
            <span className="bg-gray-200 rounded-lg w-2/4 h-2/4">Illustration</span>
          </div>
          <div className="flex-auto w-screen h-auto">
            <form className="p-5 md:p-10" onSubmit={(e) => e.preventDefault()}>
              <p className="label text-2xl mb-8">Set new password</p>
              <div className="flex items-center">
                Enter your new password and password confirmation
              </div>
              <div className="mb-5" style={{ marginTop: 20 }}>
                <label
                  className="label block mb-1"
                  htmlFor="password"
                  style={{ fontWeight: 'normal' }}
                >
                  New Password
                </label>
                <div className="relative text-gray-600 w-full max-w-xs">
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Type new password here"
                    className="input input-bordered w-full max-w-xs"
                    {...register('password', {
                      required: 'You must specify a password',
                      minLength: 8,
                      maxLength: 36,
                    })}
                  />
                  <span className="absolute inset-y-0 right-2 flex items-center pl-2">
                    <button
                      type="button"
                      className="p-1 focus:outline-none focus:shadow-outline"
                      onClick={toggleShowPassword}
                    >
                      <EyesIcon />
                    </button>
                  </span>
                </div>
                {errors?.password?.type === 'required' && (
                  <p className="text-sm ml-2 mt-2 text-red-600">This field is required</p>
                )}
                {errors?.password?.type === 'minLength' && (
                  <p className="text-sm ml-2 mt-2 text-red-600">Minimal length password 8</p>
                )}
                {errors?.password?.type === 'maxLength' && (
                  <p className="text-sm ml-2 mt-2 text-red-600">
                    Password cannot exceed 36 characters
                  </p>
                )}
              </div>
              <div className="">
                <label
                  className="label block mb-1"
                  htmlFor="password"
                  style={{ fontWeight: 'normal' }}
                >
                  Password Confirmation
                </label>
                <div className="relative text-gray-600 w-full max-w-xs">
                  <input
                    name="password_repeat"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Type password confirmation here"
                    className="input input-bordered w-full max-w-xs"
                    {...register('password_repeat', {
                      validate: (value) =>
                        value === password.current || 'The password do not match',
                    })}
                  />
                  <span className="absolute inset-y-0 right-2 flex items-center pl-2">
                    <button
                      type="button"
                      className="p-1 focus:outline-none focus:shadow-outline"
                      onClick={toggleShowPassword}
                    >
                      <EyesIcon />
                    </button>
                  </span>
                </div>
                {errors.password_repeat && (
                  <p className="text-sm ml-2 mt-2 text-red-600">{errors.password_repeat.message}</p>
                )}
              </div>

              <div className="mt-6">
                <button type="submit" className="btn" onClick={handleSubmit(onSubmit)}>
                  Create a new password
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="">Sorry, the reset password token not found</div>
      )}
    </div>
  );
}
