import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, Sidebar } from '../components';

export default function DashboardLayout(props) {
  // const [show, setShow] = useState(false);
  // const [profile, setProfile] = useState(false);

  const [shrinkMode, setShrinkMode] = useState(false);
  const { children, withoutFooter } = props;

  return (
    <main className="flex flex-col h-screen">
      <div className="flex flex-1 overflow-hidden">
        <Sidebar isShrink={shrinkMode} />
        <div className="flex flex-1 flex-col w-full">
          <div className="flex flex-col mb-1">
            <Header onShrink={() => setShrinkMode(!shrinkMode)} />
          </div>
          <div className="flex flex-col overflow-y-auto w-full">
            <div className="flex-grow px-4 py-10">{children}</div>
            {!withoutFooter && (
              <div className="flex">
                <Footer />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  withoutFooter: PropTypes.bool,
};

DashboardLayout.defaultProps = {
  withoutFooter: false,
};
