/* eslint-disable no-console */
import { getToken } from 'firebase/messaging';
import firebase from '../firebase';

const { messaging } = firebase;

export const getTokens = async (setTokenFound) => {
  let currentToken = '';

  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      console.log('FCM Token: ', currentToken);
      setTokenFound(true);
    } else {
      console.log('FCM Token: not found');
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};
