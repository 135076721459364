import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

const firebaseApp = initializeApp({
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: 'AIzaSyCuuw0OrlS9wHub66DQqV50_eBnzBKY8g4',
  authDomain: 'nadi-f98db.firebaseapp.com',
  projectId: 'nadi-f98db',
  storageBucket: 'nadi-f98db.appspot.com',
  messagingSenderId: '156352971610',
  appId: '1:156352971610:web:a4e41891066257f7fa6963',
  measurementId: 'G-TQB1Z3EL4X',
});

const db = getFirestore(firebaseApp);
const messaging = getMessaging(firebaseApp);

export default { firebaseApp, db, messaging };
