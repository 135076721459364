import React from 'react';
import { Header, FormBoxResetPassword } from './components';

export default function ResetPassword() {
  return (
    <div className="App">
      <Header mode="forgot-password" />
      <div className="container mx-auto items-center justify-center w-full pt-28">
        <FormBoxResetPassword />
      </div>
    </div>
  );
}
