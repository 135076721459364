import { useState, useEffect } from 'react';
import { ChatSDK, getChatsList } from '../chat-sdk';
import { useAuthStore } from '../../../auth/stores';

export function useChatHook() {
  const [chatList, setChatList] = useState(JSON.parse(localStorage.getItem('chatList')) || []);
  const [chatMessages, setMessages] = useState([]);
  const [isSDKReady, setIsSDKReady] = useState(false);
  const [stateAuth, authActions] = useAuthStore();

  useEffect(() => {
    const hasToken = !!stateAuth.data.livechatToken;
    if (stateAuth.isAuth && hasToken && !isSDKReady) {
      ChatSDK.init({
        personal_access_token: stateAuth.data.livechatToken
      });
    }
  }, [stateAuth.data.livechatToken]);

  useEffect(() => {
    const getActiveChats = async () => {
      try {
        authActions.reloadDataUser();
        const { chats_summary } = await getChatsList();
        console.log('Chat summary ', chats_summary);
        console.log('Email ', stateAuth.data.email);
        const activeChats = chats_summary.filter((chat) => {
          // Get the active chat
          const isActive = chat?.last_thread_summary?.active;
          // Dont Get the Queue Chat
          const notQueue = !chat?.last_thread_summary?.queue;
          // Get the chat only of the current doctor
          const userIds = chat?.last_thread_summary?.user_ids;
          const isCurrentDoctor = userIds.includes('mypharma.livechat@nadihealth.com');
          // const isArchived = chat?.last_thread_summary?.isArchived;
          return isActive && notQueue && isCurrentDoctor;
        });

        setChatList(activeChats);
      } catch (error) {
        console.error('Unable to fetch agent chats: ', error);
      }
    };

    const setReadyFlag = () => {
      setIsSDKReady(true);
      getActiveChats();
      console.log('chat is ready');
    };
    ChatSDK.on('ready', setReadyFlag);
    return () => {
      ChatSDK.off('ready', setReadyFlag);
    };
  });

  useEffect(() => {
    const handleIncomingChats = ({ payload }) => {
      const incomingChat = payload?.chat;
      const newList = [...chatList, incomingChat];
      localStorage.setItem('chatList', JSON.stringify(newList));
      setChatList(newList);
    };

    const handleClosingThread = ({ payload }) => {
      const closedChat = payload?.chat_id;
      const updatedChatList = chatList.filter(({ id }) => id !== closedChat);
      setChatList(updatedChatList);
    };

    ChatSDK.on('incoming_chat', handleIncomingChats);
    ChatSDK.on('chat_deactivated', handleClosingThread);

    return () => {
      ChatSDK.off('incoming_chat', handleIncomingChats);
      ChatSDK.off('chat_deactivated', handleClosingThread);
    };
  });

  useEffect(() => {
    localStorage.setItem('chatList', JSON.stringify(chatList));
  }, [chatList]);

  useEffect(() => {
    const newList = localStorage.getItem('chatList') ? JSON.parse(localStorage.getItem('chatList')) : [];
    setChatList(newList);
    console.log('di update kembali chat list', newList);
  }, []);

  useEffect(() => {
    const handleNewMessages = ({ payload }) => {
      console.log('user list', chatList);
      // eslint-disable-next-line max-len
      const userList = chatList.find(x => x.id === payload.chat_id).users.find(users => users.id === payload.event.author_id);
      console.log('isi user List ', userList);
      if (userList?.type === 'customer') {
        const newMessage = {
          userName: userList.name,
          message: payload.event.text,
        };
        setMessages([...chatMessages, newMessage]);
      }
    };

    ChatSDK.on('incoming_event', handleNewMessages);

    return () => {
      ChatSDK.off('incoming_event', handleNewMessages);
    };
  });

  /** End of handling Live Chat */

  return {
    chatMessages
  };
}
