/* eslint-disable no-unused-vars */
import { apiClient } from '../../commons/services';

const groupPath = 'v1/admin/auth';
const groupPathSystemUser = 'v1/admin/system-user';

// export const getCsrfToken = () => apiClient.get('/getCsrfToken');
export const checkAuth = () => apiClient.get(`${groupPath}/check`);
export const reloadDataUser = () => apiClient.get(`${groupPath}/reload-data-user`);
export const checkResetPass = ({ _id }) => apiClient.post(`${groupPath}/reset-check`, { _id });
export const reset = ({ _id, password }) => apiClient.post(`${groupPath}/reset`, { _id, password });
export const loginAuth = ({ email, password }) =>
  apiClient.post(`${groupPath}/login`, { email, password });
export const forgotAuth = ({ email, password }) =>
  apiClient.post(`${groupPath}/forgot`, { email, password });

export const setMyAvailability = ({ id, availability }) =>
  apiClient.post(`${groupPathSystemUser}/update/${id}/available`, { isAvailable: availability });
