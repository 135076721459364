import React from 'react';
import PropTypes from 'prop-types';

export function NadiLogo({ width = '86px', height = '24px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7503 23.991L5.72242 9.2263V23.991H0V0.00927734H5.38884L16.4683 14.8058V0.00927734H22.1629V23.991H16.7503Z"
        fill="#1B2932"
      />
      <path d="M79.2832 23.991V0.00927734H85.3789V23.991H79.2832Z" fill="#1B2932" />
      <path
        d="M64.5109 0.009928H54.1025V23.9917H60.1863H64.5109C66.1044 24.0207 67.6878 23.7318 69.1685 23.1421C70.6492 22.5523 71.9975 21.6734 73.1347 20.5567C74.2719 19.44 75.1752 18.1079 75.7918 16.6382C76.4084 15.1685 76.726 13.5906 76.726 11.9968C76.726 10.403 76.4084 8.82517 75.7918 7.35547C75.1752 5.88577 74.2719 4.55367 73.1347 3.43697C71.9975 2.32027 70.6492 1.44137 69.1685 0.851591C67.6878 0.261814 66.1044 -0.0270107 64.5109 0.00198572V0.009928ZM64.5109 17.9158H60.1863V5.83559C60.1863 5.92296 60.1863 6.01032 60.1863 6.09769H64.5069C65.2961 6.07723 66.0813 6.215 66.8164 6.50286C67.5514 6.79072 68.2214 7.22285 68.7868 7.77376C69.3521 8.32468 69.8015 8.98322 70.1083 9.71057C70.4151 10.4379 70.5732 11.2193 70.5732 12.0087C70.5732 12.7981 70.4151 13.5796 70.1083 14.3069C69.8015 15.0342 69.3521 15.6928 68.7868 16.2437C68.2214 16.7946 67.5514 17.2267 66.8164 17.5146C66.0813 17.8025 65.2961 17.9402 64.5069 17.9198L64.5109 17.9158Z"
        fill="#1B2932"
      />
      <path
        d="M50.1268 12.0061C50.1268 12.0577 50.1268 12.1093 50.1268 12.161C50.0861 15.3168 48.8029 18.3294 46.5557 20.5454C44.3084 22.7615 41.2782 24.0023 38.1221 23.999V17.9152C39.6869 17.9162 41.1883 17.297 42.2974 16.1931C43.4065 15.0892 44.0328 13.5907 44.0391 12.026V6.09307C44.0401 4.47987 44.6814 2.93304 45.8221 1.79234C46.9628 0.651636 48.5097 0.0103293 50.1229 0.00927734V11.8473C50.1268 11.8989 50.1268 11.9545 50.1268 12.0061Z"
        fill="url(#paint0_linear_867_32001)"
      />
      <path
        d="M50.1285 12.0074C50.1285 12.059 50.1285 12.1106 50.1285 12.1623V23.9883C48.5167 23.9883 46.9707 23.3491 45.8295 22.2109C44.6882 21.0726 44.045 19.5283 44.0408 17.9165V11.9836C44.0369 10.8151 43.6868 9.67399 43.0349 8.7043C42.383 7.73461 41.4583 6.97981 40.3778 6.53519C39.2972 6.09057 38.1091 5.97607 36.9635 6.20615C35.8179 6.43622 34.7662 7.00054 33.941 7.82789C33.1159 8.65523 32.5544 9.70849 32.3274 10.8547C32.1004 12.0009 32.2181 13.1887 32.6656 14.2681C33.1131 15.3474 33.8704 16.27 34.8418 16.9194C35.8133 17.5687 36.9553 17.9157 38.1238 17.9165V24.0002C35.7509 24.0002 33.4312 23.2965 31.4583 21.9781C29.4853 20.6597 27.9477 18.7859 27.0398 16.5935C26.1319 14.4011 25.8945 11.9888 26.3578 9.6615C26.821 7.33423 27.964 5.19661 29.6422 3.51898C31.3203 1.84135 33.4583 0.69908 35.7858 0.236629C38.1132 -0.225823 40.5255 0.012321 42.7175 0.920941C44.9096 1.82956 46.783 3.36784 48.1007 5.34123C49.4185 7.31462 50.1214 9.63447 50.1206 12.0074H50.1285Z"
        fill="#0BDBD2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_867_32001"
          x1="38.9163"
          y1="18.4314"
          x2="54.9597"
          y2="2.41976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0BDBD2" />
          <stop offset="0.49" stopColor="#268C8F" />
        </linearGradient>
      </defs>
    </svg>
  );
}

NadiLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

NadiLogo.defaultProps = {
  width: '86px',
  height: '24px',
};
