import { apiClient } from '../../../../commons/services/apiClient';

const prefix = '/v1/admin';

export const listOrder = ({
  limit,
  search,
  skip,
  sortdir,
  sortby,
  delivery_service,
  status,
  start_date,
  end_date,
}) =>
  apiClient.get(
    `${prefix}/ehealth/orders/list?skip=${skip}&search=${search}&limit=${limit}&sortdir=${sortdir}&sortby=${sortby}&delivery_service=${delivery_service}&status=${status}&start_date=${start_date}&end_date=${end_date}`,
  );

export const detailOrder = (id) => apiClient.get(`${prefix}/ehealth/orders/detail/${id}`);

export const listRefundOrder = ({ limit, search, skip, sortdir, sortby, start_date, end_date }) =>
  apiClient.get(
    `${prefix}/ehealth/order-refunds/list?skip=${skip}&limit=${limit}&search=${search}&sortdir=${sortdir}&sortby=${sortby}&start_date=${start_date}&end_date=${end_date}`,
  );

export const detailRefund = (id) => apiClient.get(`${prefix}/ehealth/order-refunds/detail/${id}`);

export const updateStatusOrder = ({ orderId, orderStatus, fulfillmentItems, cancelReason }) => {
  return apiClient.post(`${prefix}/ehealth/orders/update/${orderId}/status`, { orderStatus, fulfillmentItems, cancelReason });
};

export const pickupRequest = ({ orderId, shipmentId }) => {
  return apiClient.post(`${prefix}/ehealth/orders/${orderId}/shipment/${shipmentId}/pickup-request`);
};
