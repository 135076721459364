import { createStore, createHook } from 'react-sweet-state';
import { pickupRequest } from '../api';

const Store = createStore({
  initialState: {
    isLoading: false,
    data: [],
    error: false,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    actionUpdate:
      ({ orderId, shipmentId }) =>
      async ({ setState }) => {
        try {
          const res = await pickupRequest({ orderId, shipmentId });

          if (res.data && res.data.payload === 200) {
            await new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });
          }
        } catch (error) {
          setState({
            data: {},
            error: error && error.response ? error.response.message : 'Data not found',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'Order Pickup Request',
});

export const useOrderPickupRequest = createHook(Store);
