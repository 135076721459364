import { createStore, createHook } from 'react-sweet-state';
import { referTo, previewLetter } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    error: null,
    data: [],
  },
  actions: {
    submitRefer:
      (body) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          // await referTo(body);
          const res = await referTo(body, getState());
          if (res.data && res.data.code === 200) {
            setState({ isLoading: false, data: res.data.payload.updatedAppointment });
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
    previewLetter: (body) => async () => {
      try {
        const res = await previewLetter(body);

        if (res.data?.code === 200) {
          return res.data.payload.referData;
        }
      } catch (err) {
        return new Error(err.response.data?.error?.message || 'Unknown add data error');
      }
    },
  },
  name: 'patientReferAction',
});

export const useActionReferStore = createHook(Store);
