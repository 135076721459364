import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="items-center p-4 footer text-xs">
      <div className="items-center grid-flow-col ml-8">
        <p>© 2022 NADI APP - All right reserved</p>
      </div>
      <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end mr-6">
        <Link to="/support"> Support</Link>
        <span>|</span>
        <Link to="/docs"> Docs</Link>
      </div>
    </footer>
  );
}
