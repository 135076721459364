/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Route, NavLink, Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import { BeakerIcon } from '@heroicons/react/solid';
import { Navigation } from './Navigation';
import { SIDE_MENU_ITEMS } from '../../settings/constants';
import { useAuthStore } from '../../auth/stores';
import { NadiLogo } from '../../assets/icons/NadiLogo';
import { useNavigation, useNavigationUpdate } from '../../context/NavContext';
import './MenuCollapse.css';

const menuItem = SIDE_MENU_ITEMS;

const filterParentModuleData = (roles, parentMnu) => {
  if (!_.isArray(roles)) {
    console.log('Oops, something wrong just happen fPM');
  }
  menuItem.forEach((mnu, index) => {
    // console.log('🐇  mnu ', mnu, 'index: :', index, 'roles: ', roles);
    if (roles?.some((q) => mnu.code.includes(q))) {
      parentMnu.push(mnu);
    }
  });
};
function SidebarListMenu(props) {
  const { values, parents, onChange, shrinkMode } = props;
  const [authState, authActions] = useAuthStore();
  const currentParentAccess = authState.data?.roles[0].modules;
  const [currentModules, setCurrentModules] = useState([]);
  useEffect(() => {
    const accessModuleData = [];
    filterParentModuleData(currentParentAccess, accessModuleData);
    setCurrentModules(accessModuleData);
  }, [currentParentAccess]);

  return (
    <div className="rounded-box shadow-md btn-ghost bg-white">
      <Listbox as="div" className="space-y-1" value={values} onChange={onChange}>
        {({ open }) => (
          <>
            <div className="flex relative">
              <Listbox.Button className="flex justify-center space-x-1 items-center text-nadi-primary p-4">
                {!shrinkMode ? (
                  <>
                    <div className="flex-grow w-9 h-9 mr-3 bg-transparent">
                      <span>{values.icon}</span>
                    </div>
                    <div className="flex-shrink text-[#3B4F5B] w-28 text-left font-bold text-sm">
                      {values.name}
                    </div>
                  </>
                ) : null}
                <div className="flex-grow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 9L12.5 5L8 9"
                      stroke="#61727D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 15L12.5 19L8 15"
                      stroke="#61727D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Listbox.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className="absolute mt-20 w-full rounded-md bg-white shadow-lg z-10"
              >
                <Listbox.Options static>
                  {currentModules.map((item) =>
                    item.id !== values.id ? (
                      <Listbox.Option
                        key={item.id}
                        value={item}
                        disabled={item.unavailable}
                        className="hover:bg-gray-600 hover:text-white px-4 py-2 text-sm"
                      >
                        {item.name}
                      </Listbox.Option>
                    ) : null,
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

SidebarListMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  shrinkMode: PropTypes.bool.isRequired,
};
export function Sidebar(props) {
  // const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currMenu = useNavigation();
  const switchMenu = useNavigationUpdate();

  const [authState, authActions] = useAuthStore();

  const [currentUserRole, setcurrentUserRole] = useState();
  const [selectedMenu, setselectedMenu] = useState(menuItem[currMenu]);
  const [bufferSelectMenu, setbufferSelectMenu] = useState();
  const [authorizedMenu, setauthorizedMenu] = useState();
  switchMenu(selectedMenu.id - 1);
  const { isShrink } = props;
  const styles = { sideBar: 'absolute lg:relative h-auto hidden lg:block' };
  let prevParentMenu = useRef(selectedMenu.name).current;

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    const accessModuleData = [];
    filterParentModuleData(authState.data?.roles[0].modules, accessModuleData);
    if (accessModuleData.length > 0) {
      const currId = parseInt(currMenu, 10) + 1;
      const check = accessModuleData.find((item) => item.id === currId);
      if (check === undefined) {
        setselectedMenu(menuItem[accessModuleData[0].id - 1]);
      }
    }
  }, [authActions.data]);

  useEffect(() => {
    setbufferSelectMenu(selectedMenu);
  }, []);

  useEffect(() => {
    const whichRole = authState.data.roles[0].code;
    setcurrentUserRole(whichRole);
  }, [currentUserRole]);

  useEffect(() => {
    const wherMnu = [];
    const whichRole = authState.data.roles[0].code;
    selectedMenu.childMenu.forEach((mnu) => {
      if (mnu.accessRole?.some((q) => whichRole === q)) {
        wherMnu.push(mnu);
      }
    });
    setauthorizedMenu(wherMnu);
    // console.log('🇦🇷  wherMnu', wherMnu);
    // console.log('🇧🇼  bufferSelectMenu', bufferSelectMenu);
    // console.log('🏎  authorizedMenu', authorizedMenu);
    setisLoading(false);
  }, [bufferSelectMenu, authState.data.roles[0].code]);

  useEffect(() => {
    const { name } = selectedMenu;
    if (prevParentMenu !== name) {
      history.push(selectedMenu.childMenu[0].path);
    }
    return () => {
      prevParentMenu = name;
    };
  }, [selectedMenu.name]);

  return (
    <>
      <aside
        className={`${styles.sideBar} ${isShrink ? 'w-24' : 'w-72'}`}
        style={{ background: '#F8FBFB' }}
      >
        <div className="flex flex-wrap justify-center py-9 content-center">
          {!isShrink && <NadiLogo />}
        </div>
        <div className="flex items-center justify-center py-0 px-8 mb-7">
          <SidebarListMenu shrinkMode={isShrink} onChange={setselectedMenu} values={selectedMenu} />
        </div>
        {!isLoading && (
          <div className="flex overflow-y-auto justify-center h-3/4 w-full">
            <Navigation
              activeItemId={location.pathname}
              onSelect={({ path }) => {
                history.push(path);
              }}
              items={authorizedMenu}
            />
          </div>
        )}
      </aside>
    </>
  );
}

Sidebar.propTypes = {
  isShrink: PropTypes.bool,
};

Sidebar.defaultProps = {
  isShrink: false,
};
