import { createStore, createHook } from 'react-sweet-state';
import { listAppointmentApi } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    limit: 20,
    skip: 0,
    search: '',
    sort: 'asc',
    sortby: 'queueNumber',
    paid: true,
    doctor: '',
    status: '10,20,25,27,28',
    type: '',
    startDate: '',
    endDate: '',
    available: '',
    method: '',
    data: [],
    total: 0,
    error: false,
    // form: {},
    // activePage: 0,
    // maxPageLimit: 5,
    // minPageLimit: 0,
    // activeTypeTabsIndex: 0,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadDataAll:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });
        // console.log('ini getState', getState());
        try {
          const res = await listAppointmentApi(getState());
          console.log('INI DATA LISTTTTTT ap', res);
          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.appointment,
              total: res.data.payload.total,
              isLoading: false,
            });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
  },
  name: 'listAllRequestEClinic',
});

export const useAllListStore = createHook(Store);
