import { createStore, createHook } from 'react-sweet-state';
import { listAppointmentApi } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    limit: 10,
    skip: 0,
    search: '',
    sort: 'asc',
    sortby: 'queueNumber',
    doctor: '',
    status: '20,25,27,30,35,40,45,95',
    type: '',
    paid: true,
    startDate: '',
    endDate: '',
    available: '',
    data: [],
    total: 0,
    error: false,
    form: {},
    activePage: 0,
    maxPageLimit: 5,
    minPageLimit: 0,
    activeTypeTabsIndex: 0,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        // console.log('newState_____', newState);
        setState(newState);
      },
    resetStore:
      () =>
      async ({ setState }) => {
        setState({
          isLoading: true,
          limit: 10,
          skip: 0,
          search: '',
          sort: 'asc',
          sortby: 'queueNumber',
          // doctor: '',
          // status: '20,25,27,30,35,40,45,95',
          type: '',
          startDate: '',
          endDate: '',
          available: '',
          data: [],
          total: 0,
          error: false,
          form: {},
          activePage: 0,
          maxPageLimit: 5,
          minPageLimit: 0,
        });
      },
    loadData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });
        // console.log('getState_____', getState());
        try {
          const res = await listAppointmentApi(getState());
          // console.log('res.data', res.data);
          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.appointment,
              total: res.data.payload.total,
              skip: res.data.payload.skip,
              limit: res.data.payload.limit,
              isLoading: false,
            });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
  },
  name: 'listAppointmentEClinic',
});

export const useListStore = createHook(Store);
