import { createStore, createHook } from 'react-sweet-state';
import { listOrder } from '../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    limit: 10,
    skip: 0,
    search: '',
    sortdir: '',
    sortby: '',
    status: '',
    start_date: '',
    end_date: '',
    data: [],
    total: 0,
    error: false,
    delivery_service: '',
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadListOrder:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });

        try {
          const res = await listOrder(getState());

          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.orders,
              total: res.data.payload.total,
              isLoading: false,
            });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
    resetList:
      () =>
      async ({ setState }) => {
        setState({
          activePage: 0,
          isLoading: false,
          limit: 10,
          skip: 0,
          search: '',
          sortdir: '',
          sortby: '',
          start_date: '',
          end_date: '',
          data: [],
          total: 0,
          error: false,
          delivery_service: '',
        });
      },
  },
  name: 'listOrder',
});

export const useListOrderStores = createHook(Store);
