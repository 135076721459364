import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DashboardLayout from '../../../layout/DashboardLayout';
import apologize from '../../../img/apologize-illustration.png';

export default function PageNotFound() {
  useEffect(() => {
    document.title = '403 - Nadi Admin';
  }, []);
  return (
    <DashboardLayout withoutFooter>
      <div className="flex">
        <div className="flex flex-col justify-center mx-auto my-14">
          <img src={apologize} alt="illustration" />
          <h1
            className="text-xl lg:text-2xl font-semibold text-center"
            style={{ color: '#3B4F5B' }}
          >
            You do not have authorization
          </h1>
          <h1
            className="text-xl lg:text-2xl font-semibold text-center"
            style={{ color: '#3B4F5B' }}
          >
            to access this page
          </h1>
          <div className="flex justify-center mt-8 items-center">
            <Link to="/" className="btn btn-primary w-48 capitalize">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
