import { createStore, createHook } from 'react-sweet-state';
import { callbackSign, updateStatusRx } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    error: null,
    data: [],
  },
  actions: {
    callbackData:
      (rxId) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          await callbackSign(rxId);

          setState({
            isLoading: false,
          });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
    updateData:
      (rxId) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          await updateStatusRx(rxId);

          setState({
            isLoading: false,
          });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'updateRxDoctorClinicEClinic',
});

export const useUpdateRxStore = createHook(Store);
