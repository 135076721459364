import { createStore, createHook } from 'react-sweet-state';
import { listAppointmentApi } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    limit: 5,
    skip: 0,
    search: '',
    sortby: 'queueNumber',
    sortdir: 'asc',
    // doctor: '',
    status: '10,20,25,27',
    clinic: '',
    type: 'clinic',
    startDate: '',
    endDate: '',
    available: '',
    data: [],
    total: 0,
    error: false,
    // form: {},
    // activePage: 0,
    // maxPageLimit: 5,
    // minPageLimit: 0,
    // activeStatusTabsIndex: 0,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadDataAll:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });
        try {
          const res = await listAppointmentApi(getState());
          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.appointment,
              total: res.data.payload.total,
              isLoading: false,
            });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
  },
  name: 'listAllAppointmentEClinic',
});

export const useAllListStoreClinic = createHook(Store);
