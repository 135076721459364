export const dummyData = [
  {
    _id: 'A1',
    photo: '',
    name: 'Jamal mirdad',
    number: '08188893345',
    role: 'admin',
    responbility: 'My Kiddo & My Happinies',
  },
  {
    _id: 'A1',
    photo: '',
    name: 'Jamal mirdad',
    number: '0855666345',
    role: 'admin',
    responbility: 'My Kiddo & My Happinies',
  },
  {
    _id: 'A1',
    photo: '',
    name: 'Jamal mirdad',
    number: '0813234545',
    role: 'admin',
    responbility: 'My Kiddo & My Happinies',
  },
];
