/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { MenuIconDefault } from '../assets/icons/MenuIconDefault';

// Global Icon
import { ReactComponent as GlobalMenuIcon } from '../assets/icons/global/logo_global.svg';
import { ReactComponent as GlobalHomeIcon } from '../assets/icons/global/home.svg';
import { ReactComponent as GlobalSubscription } from '../assets/icons/global/subscription.svg';
// import { ReactComponent as GlobalChatIcon } from '../assets/icons/global/chat.svg';
// import { ReactComponent as GlobalReportIcon } from '../assets/icons/global/report.svg';
// import { ReactComponent as GlobalRatingIcon } from '../assets/icons/global/rating.svg';
// import { ReactComponent as GlobalBannerIcon } from '../assets/icons/global/banner.svg';
// import { ReactComponent as GlobalArticleIcon } from '../assets/icons/global/article.svg';
// import { ReactComponent as GlobalInsightIcon } from '../assets/icons/global/insight.svg';
// import { ReactComponent as GlobalPartnerIcon } from '../assets/icons/global/partner.svg';
// import { ReactComponent as GlobalCustomerIcon } from '../assets/icons/global/customer.svg';
// import { ReactComponent as GlobalHelpCenterIcon } from '../assets/icons/global/helpcenter.svg';
// import { ReactComponent as GlobalMaintenanceIcon } from '../assets/icons/global/maintenance.svg';
// import { ReactComponent as GlobalAdministratorIcon } from '../assets/icons/global/administrator.svg';

// eHealth Icon
import EHealthMenuIcon from '../assets/icons/ehealth/logo_ehealth.png';
import { ReactComponent as EHealthHomeIcon } from '../assets/icons/ehealth/home.svg';
import { ReactComponent as EHealthChatIcon } from '../assets/icons/ehealth/chat.svg';
import { ReactComponent as EHealthOrderIcon } from '../assets/icons/ehealth/order.svg';
import { ReactComponent as EHealthListProductIcon } from '../assets/icons/ehealth/listproduct.svg';
import { ReactComponent as EHealthSpecialBrandIcon } from '../assets/icons/ehealth/specialbrand.svg';
import { ReactComponent as EHealthTopProductIcon } from '../assets/icons/ehealth/topproduct.svg';
import { ReactComponent as EHealthStoreFrontIcon } from '../assets/icons/ehealth/storefront.svg';
import { ReactComponent as EHealthReviewIcon } from '../assets/icons/ehealth/review.svg';
import { ReactComponent as EHealthIncomingPrescriptionIcon } from '../assets/icons/ehealth/incomingprescription.svg';
import { ReactComponent as EHealthPrescriptionConsultationIcon } from '../assets/icons/ehealth/prescriptionconsultation.svg';
// eClinic Icon
// import { ReactComponent as EClinicMenuIcon } from '../assets/icons/eclinic/logo_mydoctor.svg';
import EClinicMenuIcon from '../assets/icons/eclinic/logo_mydoctor.png';
import { ReactComponent as EClinicDoctorIcon } from '../assets/icons/eclinic/doctor.svg';
import { ReactComponent as EClinicClinicIcon } from '../assets/icons/eclinic/clinic.svg';
import { ReactComponent as EClinicVaccinationIcon } from '../assets/icons/eclinic/vaccination.svg';
// import { ReactComponent as EClinicHistoryIcon } from '../assets/icons/eclinic/';
// import { ReactComponent as EClinicInsightsIcon } from '../assets/icons/eclinic/insights.svg';

// myHappiness Icon
import MyHappinessMenuIcon from '../assets/icons/myhappiness/logo_myhappiness.png';
// import { ReactComponent as MyHappinessMenuIcon } from '../assets/icons/eclinic/appointment.svg';
// import { ReactComponent as MyHappinessAnalyticsIcon } from '../assets/icons/myhappiness/analytics.svg';
// import { ReactComponent as MyHappinessChatIcon } from '../assets/icons/myhappiness/';
import { ReactComponent as MyHappinessArticleIcon } from '../assets/icons/myhappiness/articles.svg';
import { ReactComponent as MyHappinessActivitiesIcon } from '../assets/icons/myhappiness/activities.svg';
// import { ReactComponent as MyHappinessLiveStreamIcon } from '../assets/icons/myhappiness/livestream.svg';
// import { ReactComponent as MyHappinessPodcastIcon } from '../assets/icons/myhappiness/podcast.svg';
import { ReactComponent as MyHappinessVideosIcon } from '../assets/icons/myhappiness/videos.svg';

// Role & Clinic Icon
import { ReactComponent as RnCMenuIcon } from '../assets/icons/role_clinic/logo_roleandclinic.svg';
import { ReactComponent as RnCAppUserListIcon } from '../assets/icons/role_clinic/appuserlist.svg';
import { ReactComponent as RnCPartnerListIcon } from '../assets/icons/role_clinic/partner-list.svg';
import { ReactComponent as RnCPlaceIcon } from '../assets/icons/role_clinic/place.svg';
import { ReactComponent as RnCNadiStaffListIcon } from '../assets/icons/role_clinic/nadi-staff-list.svg';
import { ReactComponent as RnCServiceSettingIcon } from '../assets/icons/role_clinic/service-setting.svg';

const ALLROLE = [
  'ID',
  'ITD',
  'IAD',
  'ECA',
  'ECD',
  'ED',
  'ICA',
  'IVCA',
  'IOA',
  'ISM',
  'IN',
  'IPA',
  'INR',
  'INOAC',
  'INOAE',
  'INOAM',
  'CS',
  'KHC',
  'SUPERADMIN',
  'IWA',
];
const ALLEXCEPT_IWA = [
  'ID',
  'ITD',
  'IAD',
  'ECA',
  'ECD',
  'ED',
  'ICA',
  'IVCA',
  'IOA',
  'ISM',
  'IN',
  'IPA',
  'INR',
  'INOAC',
  'INOAE',
  'INOAM',
  'CS',
  'KHC',
  'SUPERADMIN',
];
const ALLEXCEPT_SA = [
  'ID',
  'ITD',
  'IAD',
  'ECA',
  'ECD',
  'ED',
  'ICA',
  'IVCA',
  'IOA',
  'ISM',
  'IN',
  'IPA',
  'INR',
  'INOAC',
  'INOAE',
  'INOAM',
  'CS',
  'KHC',
  'IWA',
];
const MyPharmaRole = ['SUPERADMIN', 'IPA', 'IWA'];

// **************** ROUTE CONSTANT START **************************
// **** Global Page Section ****
const globalMenus = [
  {
    id: 1,
    name: 'Home',
    path: '/global/dashboard',
    exact: false,
    elemBefore: () => <GlobalHomeIcon fill="#28DAD1" />,
    icon: <GlobalHomeIcon fill="#28DAD1" />,
    activeIcon: () => <GlobalHomeIcon fill="white" />,
    accessRole: ['SUPERADMIN', 'IAD', 'ECA'],
  },
  {
    id: 2,
    name: 'Users Session',
    path: '/global/user-session',
    exact: false,
    elemBefore: () => <RnCAppUserListIcon stroke="#28DAD1" />,
    icon: <RnCAppUserListIcon stroke="#28DAD1" />,
    activeIcon: () => <RnCAppUserListIcon stroke="white" />,
    accessRole: ['SUPERADMIN'],
  },
  {
    id: 3,
    name: 'Subscription',
    // path: '/global/subscription',
    exact: false,
    elemBefore: () => <GlobalSubscription stroke="#28DAD1" />,
    icon: <GlobalSubscription stroke="#28DAD1" />,
    activeIcon: () => <GlobalSubscription stroke="white" />,
    accessRole: ['SUPERADMIN'],
    subMenu: [
      {
        id: 31,
        name: 'Manage Plan',
        path: '/global/subscription/manage-plan',
        exact: false,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ['SUPERADMIN'],
      },
      {
        id: 32,
        name: 'Manage User',
        path: '/global/subscription/manage-user',
        exact: false,
        icon: <MenuIconDefault />,
        activeIcon: () => <MenuIconDefault />,
        accessRole: ['SUPERADMIN'],
      },
    ],
  },
  // {
  //   id: 2,
  //   name: 'Chat',
  //   path: '/global/chat',
  //   exact: false,
  //   icon: <GlobalChatIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalChatIcon fill="white" />,
  // },
  // {
  //   id: 3,
  //   name: 'Report',
  //   path: '/global/report',
  //   exact: false,
  //   icon: <GlobalReportIcon stroke="#28DAD1" />,
  //   activeIcon: <GlobalReportIcon stroke="white" />,
  // },
  // {
  //   id: 4,
  //   name: 'Rating',
  //   path: '/global/rating',
  //   exact: false,
  //   icon: <GlobalRatingIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalRatingIcon fill="white" />,
  // },
  // {
  //   id: 5,
  //   name: 'Banner',
  //   path: '/global/banner',
  //   exact: false,
  //   icon: <GlobalBannerIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalBannerIcon fill="white" />,
  // },
  // {
  //   id: 6,
  //   name: 'Article',
  //   path: '/global/article',
  //   exact: false,
  //   icon: <GlobalArticleIcon stroke="#28DAD1" />,
  //   activeIcon: <GlobalArticleIcon stroke="white" />,
  // },
  // {
  //   id: 7,
  //   name: 'Insights',
  //   path: '/global/insights',
  //   exact: false,
  //   icon: <GlobalInsightIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalInsightIcon fill="white" />,
  // },
  // {
  //   id: 8,
  //   name: 'Partner',
  //   path: '/global/partner',
  //   exact: false,
  //   icon: <GlobalPartnerIcon stroke="#28DAD1" />,
  //   activeIcon: <GlobalPartnerIcon stroke="white" />,
  // },
  // {
  //   id: 9,
  //   name: 'Customer',
  //   path: '/global/customer',
  //   exact: false,
  //   icon: <GlobalCustomerIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalCustomerIcon fill="white" />,
  // },
  // {
  //   id: 10,
  //   name: 'Help Center',
  //   path: '/global/helpcenter',
  //   exact: false,
  //   icon: <GlobalHelpCenterIcon stroke="#28DAD1" />,
  //   activeIcon: <GlobalHelpCenterIcon stroke="white" />,
  // },
  // {
  //   id: 11,
  //   name: 'Maintenance',
  //   path: '/global/maintence',
  //   exact: false,
  //   icon: <GlobalMaintenanceIcon stroke="#28DAD1" />,
  //   activeIcon: <GlobalMaintenanceIcon stroke="white" />,
  // },
  // {
  //   id: 12,
  //   name: 'Administrator',
  //   path: '/global/administrator',
  //   exact: false,
  //   elemBefore: () => <GlobalAdministratorIcon fill="#28DAD1" />,
  //   icon: <GlobalAdministratorIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalAdministratorIcon fill="white" />,
  // },
  // {
  //   id: 13,
  //   name: 'Home Angga',
  //   path: '/global/home-angga',
  //   exact: false,
  //   elemBefore: () => <GlobalHomeIcon fill="#28DAD1" />,
  //   icon: <GlobalHomeIcon fill="#28DAD1" />,
  //   activeIcon: <GlobalHomeIcon fill="white" />,
  //   role: ['SUPERADMIN', 'IAD', 'ECA'],
  // },
];
// **** Global Page End ****

// **** eHealth Page Section ****
const eHealthMenus = [
  {
    id: 1,
    name: 'Home',
    path: '/e-health/dashboard',
    exact: true,
    elemBefore: () => <EHealthHomeIcon fill="#28DAD1" />,
    icon: <EHealthHomeIcon fill="#28DAD1" />,
    activeIcon: () => <EHealthHomeIcon fill="white" />,
    accessRole: ALLEXCEPT_IWA,
  },
  // {
  //   id: 2,
  //   name: 'Chat',
  //   path: '/e-health/chat',
  //   exact: false,
  //   elemBefore: () => <EHealthChatIcon fill="#28DAD1" />,
  //   icon: <EHealthChatIcon fill="#28DAD1" />,
  //   activeIcon: () => <EHealthChatIcon fill="white" />,
  // },
  // {
  //   id: 3,
  //   name: 'Prescription Consultation',
  //   path: '/e-health/prescription-consultation',
  //   exact: false,
  //   elemBefore: () => <EHealthPrescriptionConsultationIcon stroke="#28DAD1" />,
  //   icon: <EHealthPrescriptionConsultationIcon stroke="#28DAD1" />,
  //   activeIcon: () => <EHealthPrescriptionConsultationIcon stroke="white" />,
  // },
  {
    id: 4,
    name: 'Incoming Prescription',
    path: '/e-health/incoming-prescription',
    exact: false,
    elemBefore: () => <EHealthIncomingPrescriptionIcon stroke="#28DAD1" />,
    icon: <EHealthIncomingPrescriptionIcon stroke="#28DAD1" />,
    activeIcon: () => <EHealthIncomingPrescriptionIcon stroke="white" />,
    accessRole: ['IPA'],
  },
  {
    id: 5,
    name: 'Order',
    path: '/e-health/order',
    exact: false,
    elemBefore: () => <EHealthOrderIcon fill="#28DAD1" />,
    icon: <EHealthOrderIcon fill="#28DAD1" />,
    activeIcon: () => <EHealthOrderIcon fill="white" />,
    accessRole: ['IWA'],
  },
  {
    id: 6,
    name: 'List Product',
    path: '/e-health/list-product',
    exact: false,
    elemBefore: () => <EHealthListProductIcon fill="#28DAD1" />,
    icon: <EHealthListProductIcon fill="#28DAD1" />,
    activeIcon: () => <EHealthListProductIcon fill="white" />,
    accessRole: ['IWA', 'IN', 'IPA', 'INOAE'],
    subMenu: [
      {
        id: 61,
        name: 'Supplement',
        path: '/e-health/list-product/supplement',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ['IWA', 'IN', 'INOAE'],
      },
      {
        id: 62,
        name: 'Fast Delivery',
        path: '/e-health/list-product/fast-delivery',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ['IWA', 'INOAE'],
      },
      {
        id: 63,
        name: 'Night Delivery',
        path: '/e-health/list-product/night-delivery',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ['IWA', 'INOAE'],
      },
      {
        id: 64,
        name: 'Medicine',
        path: '/e-health/list-product/medicine',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ['IWA', 'IPA', 'INOAE'],
      },
    ],
  },
  // {
  //   id: 7,
  //   name: 'Special Brand',
  //   path: '/e-health/special-brand',
  //   exact: false,
  //   elemBefore: () => <EHealthSpecialBrandIcon fill="#28DAD1" />,
  //   icon: <EHealthSpecialBrandIcon fill="#28DAD1" />,
  //   activeIcon: <EHealthSpecialBrandIcon fill="white" />,
  // },
  // {
  //   id: 8,
  //   name: 'Top Product',
  //   path: '/e-health/top-product',
  //   exact: false,
  //   elemBefore: () => <EHealthTopProductIcon fill="#28DAD1" />,
  //   icon: <EHealthTopProductIcon fill="#28DAD1" />,
  //   activeIcon: <EHealthTopProductIcon fill="white" />,
  // },
  // {
  //   id: 9,
  //   name: 'Storefront',
  //   path: '/e-health/storefront',
  //   exact: false,
  //   elemBefore: () => <EHealthStoreFrontIcon fill="#28DAD1" />,
  //   icon: <EHealthStoreFrontIcon fill="#28DAD1" />,
  //   activeIcon: <EHealthStoreFrontIcon fill="white" />,
  // },
  // {
  //   id: 10,
  //   name: 'Review',
  //   path: '/e-health/review',
  //   exact: false,
  //   elemBefore: () => <EHealthReviewIcon fill="#28DAD1" />,
  //   icon: <EHealthReviewIcon fill="#28DAD1" />,
  //   activeIcon: <EHealthReviewIcon fill="white" />,
  // },
];
// **** eHealth Page End ****

// **** eClinic Page Section ****
const eClinicMenus = [
  {
    id: 1,
    name: 'Doctor',
    path: '/e-clinic/doctor/request',
    exact: false,
    elemBefore: () => <EClinicDoctorIcon fill="#28DAD1" />,
    icon: <EClinicDoctorIcon fill="#28DAD1" />,
    activeIcon: () => <EClinicDoctorIcon fill="white" />,
    accessRole: ALLEXCEPT_IWA,
    subMenu: [
      {
        id: 31,
        name: 'Request',
        path: '/e-clinic/doctor/request',
        label: 'Request-Doctor',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ALLEXCEPT_IWA,
      },
      {
        id: 32,
        name: 'Appointment',
        path: '/e-clinic/doctor/appointment',
        label: 'Appointment-Doctor',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ALLEXCEPT_IWA,
      },
    ],
  },
  {
    id: 2,
    name: 'Clinic',
    path: '/e-clinic/clinic/request',
    exact: false,
    elemBefore: () => <EClinicClinicIcon fill="#28DAD1" />,
    icon: <EClinicClinicIcon fill="#28DAD1" />,
    activeIcon: () => <EClinicClinicIcon fill="white" />,
    accessRole: ALLEXCEPT_IWA,
    subMenu: [
      {
        id: 33,
        name: 'Request',
        path: '/e-clinic/clinic/request',
        label: 'Request-Clinic',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ALLEXCEPT_IWA,
      },
      {
        id: 34,
        name: 'Appointment',
        path: '/e-clinic/clinic/appointment',
        label: 'Appointment-Clinic',
        exact: false,
        // elemBefore: () => <MenuIconDefault />,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ALLEXCEPT_IWA,
      },
    ],
  },
  {
    id: 3,
    name: 'Vaccination',
    path: '/e-clinic/vaccination',
    exact: false,
    elemBefore: () => <EClinicVaccinationIcon fill="#28DAD1" />,
    icon: <EClinicVaccinationIcon fill="#28DAD1" />,
    activeIcon: () => <EClinicVaccinationIcon fill="white" />,
    accessRole: ALLEXCEPT_SA,
  },
];
// **** eClinic Page End ****

// **** myHappiness Page Section ****
const myHappinessMenus = [
  // {
  //   id: 1,
  //   name: 'Analytics',
  //   path: '/my-happiness/dashboard',
  //   exact: true,
  //   icon: <MyHappinessAnalyticsIcon />,
  //   activeIcon: <MyHappinessAnalyticsIcon />,
  // },
  // {
  //   id: 2,
  //   name: 'Chat',
  //   path: '/my-happiness/chat',
  //   exact: false,
  //   icon: <MenuIconDefault />,
  //   activeIcon: <MenuIconDefault />,
  // },
  {
    id: 4,
    name: 'Events',
    path: '/my-happiness/activities',
    exact: false,
    elemBefore: () => <MyHappinessActivitiesIcon stroke="#28DAD1" />,
    icon: <MyHappinessActivitiesIcon stroke="red" />,
    activeIcon: () => <MyHappinessActivitiesIcon stroke="white" />,
    accessRole: ['SUPERADMIN', 'EO'],
  },
  {
    id: 1,
    name: 'Article',
    path: '/my-happiness/article',
    exact: false,
    elemBefore: () => <MyHappinessArticleIcon stroke="#28DAD1" />,
    icon: <MyHappinessArticleIcon stroke="#28DAD1" />,
    activeIcon: () => <MyHappinessArticleIcon stroke="white" />,
    accessRole: ALLEXCEPT_IWA,
  },
  // {
  //   id: 5,
  //   name: 'Live Stream',
  //   path: '/my-happiness/live-stream',
  //   exact: false,
  //   icon: <MyHappinessLiveStreamIcon />,
  //   activeIcon: <MyHappinessLiveStreamIcon />,
  // },
  // {
  //   id: 2,
  //   name: 'Podcast',
  //   path: '/my-happiness/podcast',
  //   exact: false,
  //   icon: <MyHappinessPodcastIcon />,
  //   activeIcon: <MyHappinessPodcastIcon />,
  // },
  {
    id: 2,
    name: 'Videos',
    path: '/my-happiness/videos',
    exact: false,
    elemBefore: () => <MyHappinessVideosIcon stroke="#28DAD1" />,
    icon: <MyHappinessVideosIcon stroke="#28DAD1" />,
    activeIcon: () => <MyHappinessVideosIcon stroke="white" />,
    accessRole: ALLEXCEPT_IWA,
  },
];
// **** myHappiness Page End ****

// **** Roles & Clinic Start ****
const roleAndClinic = [
  {
    id: 1,
    name: 'App User List',
    path: '/partnership/app-user-list',
    exact: true,
    elemBefore: () => <RnCAppUserListIcon stroke="#28DAD1" />,
    icon: <RnCAppUserListIcon stroke="#28DAD1" />,
    activeIcon: () => <RnCAppUserListIcon stroke="white" />,
    accessRole: ALLEXCEPT_IWA,
  },
  {
    id: 2,
    name: 'Partner List',
    path: '/partnership/partner-list',
    exact: false,
    elemBefore: () => <RnCPartnerListIcon stroke="#28DAD1" />,
    icon: <RnCPartnerListIcon stroke="#28DAD1" />,
    activeIcon: () => <RnCPartnerListIcon stroke="white" />,
    accessRole: ALLEXCEPT_IWA,
    // subMenu: [
    //   {
    //     id: 21,
    //     name: 'My Health',
    //     path: '/partnership/partner-list/my-health',
    //     exact: false,
    //     icon: <MenuIconDefault />,
    //     activeIcon: <MenuIconDefault />,
    //   },
    //   {
    //     id: 22,
    //     name: 'My Doctor',
    //     path: '/partnership/partner-list/my-doctor',
    //     exact: false,
    //     icon: <MenuIconDefault />,
    //     activeIcon: <MenuIconDefault />,
    //   },
    //   {
    //     id: 23,
    //     name: 'My Happiness',
    //     path: '/partnership/partner-list/my-happiness',
    //     exact: false,
    //     icon: <MenuIconDefault />,
    //     activeIcon: <MenuIconDefault />,
    //   },
    // ],
  },
  {
    id: 3,
    name: 'Place',
    // path: '/partnership/place',
    exact: false,
    elemBefore: () => <RnCPlaceIcon fill="#28DAD1" />,
    icon: <RnCPlaceIcon fill="#28DAD1" />,
    activeIcon: () => <RnCPlaceIcon fill="white" />,
    accessRole: ALLEXCEPT_IWA,
    subMenu: [
      {
        id: 31,
        name: 'Clinic',
        path: '/partnership/place/clinic',
        exact: false,
        icon: <MenuIconDefault />,
        activeIcon: <MenuIconDefault />,
        accessRole: ALLEXCEPT_IWA,
      },
      {
        id: 32,
        name: 'Vaccination Center',
        path: '/partnership/place/vaccination-center',
        exact: false,
        icon: <MenuIconDefault />,
        activeIcon: () => <MenuIconDefault />,
        accessRole: ALLEXCEPT_IWA,
      },
    ],
  },
  {
    id: 4,
    name: 'Nadi Staff List',
    path: '/partnership/nadi-staff-list',
    exact: true,
    elemBefore: () => <RnCNadiStaffListIcon fill="#28DAD1" />,
    icon: <RnCNadiStaffListIcon fill="#28DAD1" />,
    activeIcon: () => <RnCNadiStaffListIcon fill="white" />,
    accessRole: ALLEXCEPT_IWA,
  },
  {
    id: 5,
    name: 'Service Setting',
    path: '/partnership/service-setting',
    exact: true,
    elemBefore: () => <RnCServiceSettingIcon stroke="#28DAD1" />,
    icon: <RnCServiceSettingIcon stroke="#28DAD1" />,
    activeIcon: () => <RnCServiceSettingIcon stroke="white" />,
    accessRole: ALLEXCEPT_IWA,
  },
];
// **** Roles & Clinic End ****

export const SIDE_MENU_ITEMS = [
  {
    id: 1,
    name: 'Global',
    code: 'GLOBAL',
    childMenu: globalMenus,
    icon: <GlobalMenuIcon />,
    unavailable: false,
  },
  {
    id: 2,
    code: 'MYPHARMA',
    name: 'My Pharma',
    childMenu: eHealthMenus,
    icon: <img src={EHealthMenuIcon} alt="mypharma-icon" />,
    unavailable: false,
  },
  {
    id: 3,
    code: 'MYDOCTOR',
    name: 'My Doctor',
    childMenu: eClinicMenus,
    icon: <img src={EClinicMenuIcon} alt="mydoctor-icon" />,
    unavailable: false,
  },
  {
    id: 4,
    code: 'MYHAPPINESS',
    name: 'My Happiness',
    childMenu: myHappinessMenus,
    icon: <img src={MyHappinessMenuIcon} alt="myhappi-icon" />,
    unavailable: false,
  },
  {
    id: 5,
    code: 'RNC',
    name: 'Role & Clinic',
    childMenu: roleAndClinic,
    icon: <RnCMenuIcon />,
    unavailable: false,
  },
];

export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGOT_PASSWORD = '/forgot-password';

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = 'MYR';
