/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import moment from 'moment';
import '../css/myPharmaNotification.css';
import { useListNotifStore, usePageSettingStore } from '../stores';
import teleLogo from '../img/TeleIcon.png';
import homeVisitLogo from '../img/HomeVisitLogo.png';
import bookingClinicLogo from '../img/BookingClinicLogo.png';
import ListNotificationMyPharma from './ListNotificationMyPharma';
import ListNotificationMyDoctor from './ListNotificationMyDoctor';

export function ListTable({ data }) {
  // const [stateDelete,] = useDeleteStore();
  // const [actionsList] = useListStore();
  const history = useHistory();
  const [statePageSetting] = usePageSettingStore();
  const [state, actions] = useListNotifStore();

  const handleIcon = (e) => {
    switch (e) {
      case 'telehealth':
        return teleLogo;
      case 'homevisit':
        return homeVisitLogo;
      case 'bookclinic':
        return bookingClinicLogo;
    }
  };

  const handleLinkNull = (e) => {
    return null;
  };

  const handleLink = (e) => {
    switch (e.redirectURL) {
      case 'TELEHEALTH_LIST_APPOINTMENT':
        return '/#/telehealth';
      case 'TELEHEALTH_DETAIL_APPOINTMENT':
        return `/#/telehealth/detail/${e.appointment._id}`;
    }

    // MyPharma
    switch (e.module) {
      case 'My Pharma':
        history.push(`${e.data.redirectTo}`);
        break;
      // End My Pharma
      case 'My Doctor':
        if (e.data.redirectTo) {
          history.push(`${e.data.redirectTo}`);
        }
        if (e.data.action.includes('request')) {
          history.push(`/e-clinic/doctor/request/detail/${e.data.appointmentId}`);
        }
        history.push(`/e-clinic/doctor/appointment/detail/${e.data.appointmentId}`);
        break;
    }
  };

  const handleClickWithUpdateRead = (e, item) => {
    e.preventDefault();
    actions.updateRead(item?._id);
    handleLink(item);
  };

  let loadingUi;

  // if (stateDelete.isLoading) {
  //   loadingUi = <div className="loader-block" />;
  // }
  const defaultNotifCard = (item) => {
    return (
      <>
        <a
          key={item.name}
          href={handleLink(item)}
          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg cursor-pointer focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
        >
          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
            {/* <img alt="nadi" className="brand" src={handleIcon(item.title)} width="60px" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              viewBox="0 0 20 20"
              fill="gray"
            >
              <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
            </svg>
          </div>
          <div className="indicator ml-4">
            <span className="indicator-item badge badge-primary" />
            <div className="p-4 bg-base-100 hover:bg-base-200 place-items-center rounded-lg bordered border">
              <p className="text-xs text-gray-500">
                {moment(item.dateTime).format('hh:mm A DD MMM YYYY')}
              </p>
              <p className="text-sm font-medium text-gray-900">
                {item.title} - {item.module}
              </p>
              <p className="text-sm text-gray-500">{item.message}</p>
            </div>
          </div>
        </a>
      </>
    );
  };

  const checkModule = (item) => {
    switch (item.module) {
      case 'My Pharma':
        return <ListNotificationMyPharma item={item} handleLink={handleLinkNull} />;
      case 'My Doctor':
        return <ListNotificationMyDoctor item={item} handleLink={handleLinkNull} />;
      default:
        return defaultNotifCard(item);
    }
  };

  const listItems = state.data.map((item, keys) => {
    // const imgBase = 'https://nadi-cdn.s3-ap-southeast-1.amazonaws.com/user-management.jpeg';

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li className="mb-5" key={keys} onClick={(e) => handleClickWithUpdateRead(e, item)}>
        {/*
          This Function is to check if item.module === My Pharma
          return componen <ListNotificationMyPharma />
          else will return default Notification Card
        */}
        {checkModule(item)}
      </li>
    );
  });

  return (
    <React.Fragment>
      {/* {loadingUi} */}
      <div className="card p-5 mb-5">
        <div className="overflow-x-auto">
          <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
            <ul>{listItems}</ul>
            {state.data.length < 1 ? <h5>No data</h5> : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
