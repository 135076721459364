/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
import { createStore, createHook } from 'react-sweet-state';
import { detailUser, listApi, createPhotoUrl, updateReadNotificationById } from '../api';

const Store = createStore({
  // value of the store on initialisation
  // limit, skip, search, orderBy, orderByDir, isRead
  initialState: {
    isLoading: false,
    limit: 10,
    skip: 0,
    search: '',
    data: [],
    dataDetail: [],
    total: 0,
    orderBy: 'desc',
    // filter: '',
    error: null,
    // urlForUpload: [],
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    loadData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });

        try {
          const resp = await listApi(getState());

          if (resp.data && resp.data.code === 200) {
            setState({ data: resp.data.payload.notif, total: 10 });
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'there was an error',
          });
        }

        setState({ isLoading: false });
      },
    updateRead:
      (id) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });
        try {
          const res = updateReadNotificationById(id);

          if (res.data.success && res.data.code === 200) {
            setState({
              isLoading: false,
            });
            return res.data.payload;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err && err.response ? err.response.message : 'there was an error',
          });
          return null;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: 'listOfNotification',
});

export const useListNotifStore = createHook(Store);
