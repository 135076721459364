/* eslint-disable no-unused-vars */
import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
  initialState: {
    isLoading: false,
    limit: 10,
    skip: 0,
    search: '',
    sort: 'desc',
    data: [],
    total: 0,
    error: null,
    form: {},
    activePage: 0,
    maxPageLimit: 5,
    minPageLimit: 0,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
  },
  name: 'listPagination',
});

export const useListPaginationStore = createHook(Store);
