import SendBird from 'sendbird';

const config = {
  appId: '6B2E7080-3A71-4AB1-9EAE-55DA2E47404C',
};

const instance = new SendBird(config);

export const sendBird = instance;
export const sendBirdConfig = config;
