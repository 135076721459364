/* eslint-disable */

import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
  initialState: {
    chatUserName: localStorage.getItem('chatUserName'),
    lastMessage: localStorage.getItem('lastMessage'),
    st: localStorage.getItem('st'),
    isFocusPopup: localStorage.getItem('isFocusPopup'),
    isFocusDashboard: localStorage.getItem('dashboardWindow'),
    chatRegister: [],
  },
  actions: {
    setFocusPopup: (e) => async () => {
      localStorage.setItem('isFocusPopup', e);
    },
    setFocusDashboard: (e) => async () => {
      localStorage.setItem('isFocusDashboard', e);
    },

    // eslint-disable-next-line no-unused-vars
    setNewMessage:
      (payload, customer) =>
      async ({ setState, getState }) => {
        localStorage.setItem('lastMessage', payload.event.text);
        localStorage.setItem('chatUserName', customer);
        localStorage.setItem('st', true);
      },
  },
  name: 'chatStore',
});

export const chatDoctorStore = createHook(Store);
