/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { onMessage } from 'firebase/messaging';
import { isChrome } from 'react-device-detect';
import firebase from '../firebase';
import Auth from '../auth';
import GeneralRoutes from './routes';
import Notifications from '../notification/components/Notifications/Notifications';
import { NavProvider } from '../context/NavContext';
import { useAuthStore } from '../auth/stores';
import UserAgentLimit from '../commons/components/UserAgentLimit';
import 'react-toastify/dist/ReactToastify.css';
import { chatDoctorStore } from '../eClinic/doctor/containers/chat/stores';
import { useChatHook } from '../utils/liveChat/hooks/chat-hook';
import { socket, socketConfig } from '../commons/services';

function InlineLoader() {
  return <div id="cover-spin">{/* <h1>Loading ...</h1> */}</div>;
}

export default function App() {
  // eslint-disable-next-line no-unused-vars
  const [authState, authActions] = useAuthStore();
  const [getNotification, setNotification] = useState(false);
  // const [messageTitle, setMessageTitle] = useState('');
  // const [messageBody, setMessageBody] = useState('');
  // const [showNotificationMsg, setShowNotificationMsg] = useState(false);
  const [chatDoctorState, chatDoctorActions] = chatDoctorStore();
  const [tabHasFocus, setTabHasFocus] = useState(false);
  let audioNotif = new Audio('/audio/incoming_message.mp3');
  const { messaging } = firebase;
  const notifPermit = Notification.permission;
  const { chatMessages } = useChatHook();

  // ---------- MyPharma Notif ----------
  const checkMyPharmaPA = () => {
    const isPharmacyAssistant = authState?.data?.roles;
    if (isPharmacyAssistant) {
      if (isPharmacyAssistant[0].code === 'IPA') return true;
    }
    return false;
  }

  const checkMyPharmaWA = () => {
    const isWarehouseAdmin = authState?.data?.roles;
    if (isWarehouseAdmin) {
      if (isWarehouseAdmin[0].code === 'IWA') return true;
    }
    return false;
  }
  // ---------- END MyPharma Notif ----------

  let messageTitle = '';
  let messageBody = '';
  let showNotificationMsg = false;
  let dataNotif;
  let doctorNotif;
  const doctorLogged = authState?.data?.fullName;
  console.log('ini data user', doctorLogged);
  let isPlayAudio = true;
  const channel = new BroadcastChannel('bgMessage');
  channel.addEventListener('message', (event) => {
    const dataNotifBg = JSON.parse(event.data?.data);
    const doctorNotifBG = dataNotifBg?.appointmentData?.doctorName;
    console.log('Play Audio From Background Message', dataNotifBg);
    if (isPlayAudio) {
      // console.log('1', { isPlayAudio });
      // isPlayAudio = false;
      // console.log('2', { isPlayAudio });
      console.log('ini doctor notif', doctorNotifBG);
      if (dataNotifBg.action.includes('request')) {
        if (doctorNotifBG) {
          try {
            const audioFile = require(`../../public/audio/Incoming_Request_${doctorNotifBG}_v1.wav`);
            audioNotif = new Audio(audioFile);
            console.log('PLAY BG AUDIO');
            audioNotif.play();
            isPlayAudio = false;
          } catch (error) {
            console.log('PLAY BG AUDIO General catch');
            audioNotif.play();
            isPlayAudio = false;
          }
          throw '';
        } else {
          console.log('PLAY BG AUDIO General');
          audioNotif.play();
          isPlayAudio = false;
          throw '';
        }
      }
      // MyPharma
      // Check is user PA (IPA) loggedin before show notif and play sound
      if (checkMyPharmaPA()) {
        if (dataNotifBg.type === 'RX') {
          console.log('PLAY BG AUDIO NEW RX');
          audioNotif.play();
          isPlayAudio = false;
        }
        if (dataNotifBg.rxId) {
          console.log('PLAY BG AUDIO NEW RX DOCTOR');
          audioNotif.play();
          isPlayAudio = false;
        }
      }
      // Check if user is WA
      if (checkMyPharmaWA()) {
        if (dataNotifBg.type === 'ORDER') {
          console.log('PLAY BG AUDIO NEW ORDER');
          audioNotif.play();
          isPlayAudio = false;
        }
        if (dataNotifBg.type === 'PRODUCT') {
          console.log('PLAY BG AUDIO PRODUCT');
          audioNotif.play();
          isPlayAudio = false;
        }
      }
    }
  });

  useEffect(() => {
    const onMessageListeners = () => {
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        messageTitle = payload.notification.title;
        messageBody = payload.notification.body;
        doctorNotif = payload.data?.data?.appointmentData?.doctorName;
        showNotificationMsg = true;

        function DisplayNotifGeneral() {
          return (
            <>
              <h4>{payload.notification.title}</h4>
              <p>{payload.notification.body}</p>
            </>
          );
        }

        // Toast Push-Notification MyPharma
        function DisplayNotifMyPharma() {
          return (
            <>
              <h4>{payload.notification.title}</h4>
              <p>{payload.notification.body}</p>
            </>
          );
        }

        // function DisplayNotif20() {
        //   return (
        //     <div>
        //       <h4>{payload.notification.title}</h4>
        //       <p>{payload.notification.body}</p>
        //     </div>
        //   );
        // }
        // function DisplayNotif30() {
        //   return (
        //     <div>
        //       <h4>{payload.notification.title}</h4>
        //       <p>{payload.notification.body}</p>
        //     </div>
        //   );
        // }

        if (showNotificationMsg) {
          console.log('harusnya notif muncul');
          // if (isPlayAudio) {
          // isPlayAudio = false;
          // audioNotif.play();
          // setTimeout(() => {
          //   isPlayAudio = true;
          // }, 2000);
          // }
          // TODO: Add the necessary catch functions notification modules here
          // if (payload.data.redirectURL === 'TELEHEALTH_LIST_APPOINTMENT') {
          //   return toast.info(
          //     <Router>
          //       <Link to="/telehealth/">
          //         <DisplayNotif20 />
          //       </Link>
          //     </Router>
          //   );
          // }
          // if (payload.data.redirectURL === 'TELEHEALTH_DETAIL_APPOINTMENT') {
          //   return toast.info(
          //     <Router>
          //       <Link to={`/telehealth/detail/${payload.data.id}`}>
          //         <DisplayNotif30 />
          //       </Link>
          //     </Router>
          //   );
          // }

          // My Pharma
          if (payload.data.module === 'My Pharma') {
            const dataNotif = JSON.parse(payload.data.data);

            // Check is user PA (IPA) loggedin before show notif and play sound
            if (checkMyPharmaPA()) {
              // console.log('logged as IPA notif popup appear');
              if (dataNotif.type === 'RX') {
                audioNotif.play();
              }
              if (dataNotif.rxId) {
                audioNotif.play();
              }

              return toast.info(
                <Router>
                  <Link to={dataNotif.redirectTo}>
                    <DisplayNotifMyPharma />
                  </Link>
                </Router>,
              );
            }

            // Start -- Other Notif MyPharma
            if (checkMyPharmaWA()) {
              if (dataNotif.type === 'ORDER') {
                audioNotif.play();
              }
              if (dataNotif.type === 'PRODUCT') {
                audioNotif.play();
              }
              return toast.info(
                <Router>
                  <Link to={dataNotif.redirectTo}>
                    <DisplayNotifMyPharma />
                  </Link>
                </Router>,
              );
            }
          }
          // End -- Other Notif MyPharma

          // if (payload.data.module === 'My Pharma') {
          //   const dataNotif = JSON.parse(payload.data.data);
          //   // console.log('>>>>>>', payload.data.data);
          //   // console.log('parse json', dataNotif);
          //   if (dataNotif.type === 'RX') {
          //     audioNotif.play();
          //   }
          //   if (dataNotif.type === 'ORDER') {
          //     audioNotif.play();
          //   }
          //   if (dataNotif.type === 'PRODUCT') {
          //     audioNotif.play();
          //   }
          //   if (dataNotif.rxId) {
          //     audioNotif.play();
          //   }

          //   return toast.info(
          //     <Router>
          //       <Link to={dataNotif.redirectTo}>
          //         <DisplayNotifMyPharma />
          //       </Link>
          //     </Router>,
          //   );
          // }
          // End My Pharma

          // My Doctor
          if (payload.data.module === 'My Doctor') {
            dataNotif = JSON.parse(payload.data.data);
            // console.log('ini data', payload.data);
            console.log('ini data notif', dataNotif);
            let redirectType = 'appointment';
            let moduleType = 'doctor';
            if (dataNotif.action.includes('reschedule')) {
              moduleType = 'clinic';
              redirectType = 'appointment';
            }
            
            if (dataNotif.action.includes('request')) {
              if (doctorLogged) {
                try {
                  const audioFile = require(`../../public/audio/Incoming_Request_${doctorLogged}_v1.wav`);
                  audioNotif = new Audio(audioFile);
                  console.log('PLAY AUDIO');
                  audioNotif.play();
                } catch (error) {
                  console.log('PLAY AUDIO General catch');
                  audioNotif.play();
                }
              } else {
                console.log('PLAY AUDIO');
                audioNotif.play();
              }
              redirectType = 'request';
            } else if (dataNotif.action.includes('cancelled')) {
              redirectType = 'request';
            } else if (dataNotif.action.includes('paid')) {
              redirectType = 'appointment';
            }
            
            const path = `/e-clinic/${moduleType}/${redirectType}/detail/${dataNotif.appointmentId}`;
            return toast.info(
              <Router>
                <Link to={path}>
                  <>
                    <h4>{messageTitle}</h4>
                    <p>{messageBody}</p>
                  </>
                </Link>
              </Router>,
            );
          }
          // End My Doctor

          // TODO: please remove below line when you have added all the notifications modules
          // return toast.info(<DisplayNotifGeneral />);
        }
      });
    };

    onMessageListeners();
  });
  /**
   * Hendling Live Chat
   */
  useEffect(() => {
    const chatCount = chatMessages.length;
    const lastMessage = chatMessages[chatCount - 1];
    console.log('trigger dari sini');
    console.log('last message', lastMessage);
    function showChatNotif() {
      return (
        <div
          onClick={() => {
            const isMyPharmaFeature = window.localStorage.getItem('chimba-mnulvl') == 1;
            if (!isMyPharmaFeature) {
              window.open(
                '/#/e-clinic/chat',
                'chatwindow',
                'menubar=0, resizeable=0, width=1024, height=700, status=0',
              );
            } else {
              window.open(
                '/#/e-health/chat',
                'chatwindow',
                'menubar=0, resizeable=0, width=1024, height=700, status=0',
              );
            }
          }}
        >
          <h4>{lastMessage?.userName}</h4>
          <p>{lastMessage?.message}</p>
        </div>
      );
    }

    if ((window.location.pathname !== '/#/e-clinic/chat' || window.location.pathname !== '/#/e-health/chat') && chatCount > 0 && localStorage.getItem('windowChat') !== 'true') {
      audioNotif.play();
      if (notifPermit === 'granted' && (localStorage.getItem('isFocusDashboard') === 'false' && localStorage.getItem('isFocusPopup') === 'false')) {
        const chatNotif = new Notification(lastMessage?.userName, {
          body: lastMessage?.message,
        });
        chatNotif.addEventListener('click', () => {
          const isMyPharmaFeature = window.localStorage.getItem('chimba-mnulvl') === '1';
          if (isMyPharmaFeature) {
            window.open(
              '/#/e-clinic/chat',
              'chatwindow',
              'menubar=0, resizeable=0, width=1024, height=700, status=0',
            );
          } else {
            window.open(
              '/#/e-health/chat',
              'chatwindow',
              'menubar=0, resizeable=0, width=1024, height=700, status=0',
            );
          }
        });
      }

      if (
        localStorage.getItem('isFocusDashboard') === 'true' &&
        (localStorage.getItem('isFocusPopup') === 'false' || localStorage.getItem('isFocusPopup') === undefined || localStorage.getItem('isFocusPopup') === null) && chatCount > 0
      ) {
        return toast.info(showChatNotif());
      }
    }
  }, [chatMessages]);
  /** End of handling Live Chat */

  useEffect(() => {
    const handleFocus = () => {
      setTabHasFocus(true);
      chatDoctorActions.setFocusDashboard(true);
      console.log('focus on dashboard');
    };

    const handleBlur = () => {
      setTabHasFocus(false);
      chatDoctorActions.setFocusDashboard(false);
      console.log('focus on another window');
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  });

  useEffect(() => {
    // authActions.getCsrf();
    authActions.checkAuth();
    // authActions.reloadDataUser();
    // window.open('/#/e-clinic/chat', 'full', 'width=1024,height=700,status=0');
  }, []);

  useEffect(() => {
    const onReceiveMessage = (e) => {
      let chatLink;
      switch (window.localStorage.getItem('chimba-mnulvl')) {
        case '0': chatLink = '/#/global/chat'; break;
        case '1': chatLink = '/#/e-health/chat'; break;
        case '2': chatLink = '/#/e-clinic/chat'; break;
      }
      function showChatNotif() {
        return (
          <div
            onClick={() => {
              const isMyPharmaFeature = window.localStorage.getItem('chimba-mnulvl') == 1;
              window.open(
                `${chatLink}`,
                'chatwindow',
                'menubar=0, resizeable=0, width=1024, height=700, status=0',
              );
            }}
          >
            <h4>{localStorage.getItem('chatUserName')}</h4>
            <p>{localStorage.getItem('lastMessage')}</p>
          </div>
        );
      }

      if (
        localStorage.getItem('st') === 'true' &&
        window.location.pathname !== '/#/e-clinic/chat'
      ) {
        localStorage.setItem('st', false);
        audioNotif.play();
        if (notifPermit === 'granted' && localStorage.getItem('isFocusDashboard') === 'false' && localStorage.getItem('isFocusPopup') === 'false') {
          // eslint-disable-next-line no-new
          // if (dataNotif.action.includes('request')) {
          //   console.log('PLAY AUDIO BACKGROUND');
          //   audioNotif.play();
          // }
          const chatNotif = new Notification(localStorage.getItem('chatUserName'), {
            body: localStorage.getItem('lastMessage'),
          });
          chatNotif.addEventListener('click', () => {
            window.open(
              `${chatLink}`,
              'chatwindow',
              'menubar=0, resizeable=0, width=1024, height=700, status=0',
            );
          });
        }

        if (
          localStorage.getItem('isFocusDashboard') === 'true' &&
          localStorage.getItem('isFocusPopup') === 'false'
        ) {
          return toast.info(showChatNotif());
        }
      }
    };

    window.addEventListener('storage', onReceiveMessage);
    return () => {
      window.removeEventListener('storage', onReceiveMessage);
    };
  }, []);

  // useEffect(() => {
  //   const onReceivedChat = () => {
  //     function showChatNotif() {
  //       return (
  //         <>
  //           <h4>{localStorage.getItem('chatUserName')}</h4>
  //           <p>{localStorage.getItem('lastMessage')}</p>
  //         </>
  //       );
  //     }

  //     if (localStorage.getItem('st')) {
  //       return toast.info(showChatNotif());
  //     }
  //   };

  //   onReceivedChat();

  //   console.log('Done Notif');
  // }, [localStorage.getItem('st')]);

  if (!isChrome) {
    return <UserAgentLimit />;
  }

  useEffect(() => {
    socket.on(socketConfig.FORCE_LOGOUT, async (data) => {
      console.log('👮 Force Logout', data);
      if (data.message === 'Your session block by super admin') {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('tokenSocket');
        toast.warn('Your session has been force logout by super admin', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          closeButton: false,
          onClose: async () => {
            const id = authState.data.ID;
            await authActions.setMyAvailability({ id, availability: false });
            await authActions.logout();
            window.location.href = '/#/';
          },
        });
      }
      socket.on(socketConfig.SOFT_LOGOUT, (data) => {
        if (data.message === 'your session invalid') {
          toast.warn('Your session invalid!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            closeOnClick: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeButton: false,
            onClose: async () => {
              window.location.reload();
            },
          });
        }
      });
    });
  }, []);

  /// start of-- autologout when close tab/browser
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
    }
  })

  const handleTabClosing = async () => {
    if (!(window.location.hash.includes('/chat') || window.location.hash.includes('/video'))) {
      const id = authState.data.ID;
      let promise = await Promise.all([
        authActions.setMyAvailability({ id, availability: false }),
        authActions.logout(),
      ])
      window.location.href = '/#/';
    }
  }

  const alertUser = (event) => {
    event.preventDefault()
    if (!authState.isBtnLogout){
      event.returnValue = ''
    }
}

  /// end of--autologout when close tab/browser

  let RouterUI = <InlineLoader />;
  let GenerateWebToken;

  if (authState.isAuth) {
    RouterUI = <GeneralRoutes />;
    GenerateWebToken = <Notifications />;
  } else {
    RouterUI = <Auth />;
  }
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavProvider>
      <ToastContainer
        // style={{ minWidth: '400px', maxWidth: '600px', paddingTop: '60px', fontSize: '15px' }}
        style={{ paddingTop: '60px', fontSize: '15px' }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      // icon={false}
      />
      {GenerateWebToken}
      {RouterUI}
    </NavProvider>
  );
}
