/* eslint-disable no-unused-vars */
import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    mode: 'imageList',
    leftMenu: true,
  },
  // actions that trigger store mutation
  actions: {
    changeMode:
      (e) =>
      async ({ setState, getState }) => {
        setState({ mode: e });
      },
    leftMenuMode:
      (e) =>
      async ({ setState, getState }) => {
        setState({ mode: getState().leftMenu });
      },
  },
  // optional, mostly used for easy debugging
  name: 'listOfAdministrator',
});

export const usePageSettingStore = createHook(Store);
