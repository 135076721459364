/* eslint-disable react/prop-types */
import React from 'react';

/* This example requires Tailwind CSS v2.0+ */
import {
  XCircleIcon,
  ExclamationIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

export function Alert({ type, content }) {
  let color = 'red';
  let iconUi = <XCircleIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />;
  let title = 'Error';

  switch (type) {
    case 'warning':
      color = 'yellow';
      title = 'Warning';
      iconUi = <ExclamationIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />;

      break;
    case 'success':
      color = 'green';
      title = 'Success';
      iconUi = <CheckCircleIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />;

      break;
    case 'info':
      color = 'blue';
      title = 'Info';
      iconUi = <InformationCircleIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />;
      break;
    case 'error':
      color = 'red';
      title = 'Oops';
      iconUi = <XCircleIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />;
      break;
    default:
      break;
  }

  return (
    <div className={`bg-${color}-50 border-l-4 border-${color}-400 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">{iconUi}</div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
          <div className={`mt-2 text-sm text-${color}-700`}>{content}</div>
        </div>
      </div>
    </div>
  );
}
