import { createStore, createHook } from 'react-sweet-state';
import { updateStatusOrder } from '../api';

const Store = createStore({
  initialState: {
    isLoading: false,
    data: [],
    error: false,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    actionUpdate:
      ({ orderId, orderStatus, fulfillmentItems, cancelReason }) =>
      async ({ setState }) => {
        try {
          const res = await updateStatusOrder({
            orderId,
            orderStatus,
            fulfillmentItems,
            cancelReason
          });

          if (res.data && res.data.payload.updatedOrderStatus === 200) {
            await new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });
          }
        } catch (error) {
          setState({
            data: {},
            error: error && error.response ? error.response.message : 'Data not found',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'updateStatusOrder',
});

export const useUpdateOrderStatus = createHook(Store);
