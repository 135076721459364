/* eslint-disable max-len */
import { apiClient } from '../../../commons/services';

const prefix = '/v2/admin';
const prefixOld = '/v1/admin';

export const listRequestApi = ({
  limit,
  skip,
  sortby,
  sortdir,
  type,
  status,
  search,
  startDate,
  endDate,
  available,
  clinic,
}) =>
  apiClient.get(
    `${prefix}/eclinic/appointment/request/list?skip=${skip}&limit=${limit}&sortby=${sortby}&sortdir=${sortdir}&type=${type}&status=${status}&search=${search}&startAt=${startDate}&endAt=${endDate}&available=${available}&clinic=${clinic}`,
  );

export const listAppointmentApi = ({
  limit,
  skip,
  sortby,
  sortdir,
  type,
  status,
  search,
  startDate,
  endDate,
  available,
  clinic,
}) =>
  apiClient.get(
    `${prefix}/eclinic/appointment/list?skip=${skip}&limit=${limit}&sortby=${sortby}&sortdir=${sortdir}&type=${type}&status=${status}&search=${search}&startAt=${startDate}&endAt=${endDate}&available=${available}&clinic=${clinic}`,
  );

export const detailApi = (id) => apiClient.get(`${prefix}/eclinic/appointment/${id}`);
export const detailReschedulleApi = (id) => apiClient.get(`${prefix}/eclinic/appointment/${id}`);

export const updateStatusAppointment = (body) =>
  apiClient.post(`${prefix}/eclinic/appointment/request/update`, body);

export const updateReschedulleAppointment = (body) =>
  apiClient.post(`${prefix}/eclinic/appointment/reschedule/update`, body);

export const listPatientDiagnose = (
  { limit, skip, sort, search, startDate, endDate },
  customerId,
) =>
  apiClient.get(
    `${prefixOld}/eclinic/medical-records/list/${customerId}?skip=${skip}&limit=${limit}&sortdir=${sort}&search=${search}&startAt=${startDate}&endAt=${endDate}`,
  );

export const addMedicalHistory = (body) => {
  return apiClient.post(`${prefixOld}/eclinic/medical-records/add`, body);
};

export const referTo = (body) => {
  return apiClient.post(`${prefix}/eclinic/appointment/refer`, body);
};

export const previewLetter = (body) => {
  return apiClient.post(`${prefix}/eclinic/appointment/refer/preview`, body);
};

export const preferableTime = (body) => {
  return apiClient.post(`${prefix}/eclinic/appointment/reschedule/update`, body);
};

export const deleteReferApi = (body) => {
  return apiClient.post(`${prefix}/eclinic/appointment/refer/remove`, body);
};
