/* eslint-disable no-unused-vars */
import { createStore, createHook } from 'react-sweet-state';
import { forgotAuth } from '../api';

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    error: null,
    responseSuccess: false,
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    forgot:
      ({ email, onSuccess, onFailed }) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          const resp = await forgotAuth({ email });
          if (resp.data && resp.data.code === 200) {
            onSuccess();
            setState({
              data: resp.data.data,
              responseSuccess: true,
              error: null,
              isLoading: false,
            });
          }
        } catch (err) {
          onFailed();
          setState({
            data: {},
            isLoading: false,
            responseSuccess: false,
            error: 'Email unregistered',
          });
        }
      },
  },
  // optional, mostly used for easy debugging
  name: 'forgotPassword',
});

export const useForgotStore = createHook(Store);
