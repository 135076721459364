import { createStore, createHook } from 'react-sweet-state';
import { listRefundOrder } from '../api';

const Store = createStore({
  initialState: {
    isLoading: true,
    limit: 10,
    skip: 0,
    search: '',
    sortdir: '',
    sortby: '',
    start_date: '',
    end_date: '',
    data: [],
    total: 0,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadListRefundOrder:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });

        try {
          const res = await listRefundOrder(getState());

          if (res.data && res.data.code === 200) {
            setState({
              data: res.data.payload.orderRefunds,
              total: res.data.payload.total,
              isLoading: false,
            });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
  },
  name: 'listRefundOrder',
});

export const useListRefundOrder = createHook(Store);
