import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Page404 from './components/PageNotFound';

export default function index() {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <Page404 />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
