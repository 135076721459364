/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { createStore, createHook } from 'react-sweet-state';
import axios from 'axios';
import firebase from '../../firebase';
// code di bawah ini jangan di rubah. jika ingin merubah, harus konfirmasi ke tim Global
import {
  checkAuth,
  /* getCsrfToken, */ loginAuth,
  reloadDataUser,
  setMyAvailability,
} from '../api';
import { apiClient, socket, socketConfig } from '../../commons/services';

async function clearPNTokenWeb() {
  const baseApi = process.env.REACT_APP_BASE_API;
  try {
    const data = await axios.post(
      `${baseApi}/v1/admin/auth/push-notification-token-web`,
      {
        token: 'null',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      },
    );
    return [data, null];
  } catch (error) {
    return [null, error];
  }
}

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    initialAuth: false,
    isLoading: false,
    isAuth: false,
    data: {},
    error: null,
    block: false,
    isBtnLogout: false
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    // getCsrf: () => async ({ setState }) => {
    //   setState({ isLoading: true, error: null });
    //     try {
    //       const resp = await getCsrfToken();
    //       if (resp.data.success && resp.status === 200) {
    //         apiClient.defaults.headers = {
    //           'X-XSRF-TOKEN': resp.data.payload.csrfToken,
    //         };
    //         setState({ isLoading: false, error: null });
    //       }
    //     } catch (err) {
    //       setState({ isLoading: false });
    //     }
    // },
    checkAuth:
      () =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null, block: false });
        try {
          const resp = await checkAuth();
          if (resp.data && resp.data.code === 200) {
            socket.emit(socketConfig.LOGIN, {
              data: {
                ...resp.data.data,
                roles: resp.data.data.rolesAgg,
                token: window.localStorage.getItem('tokenSocket'),
              },
            });

            socket.on(socketConfig.LOGIN, async (data) => {
              if (data.message === 'Login Block') {
                // console.log('👮 Login Block');
                setState({ isAuth: false, data: {}, block: true });
              } else {
                // console.log('🔑 Login Allow');
                setState({ isAuth: true, data: resp.data.data });
              }
            });
          }
        } catch (err) {
          setState({ isAuth: false, data: {} });
        }
        setState({ initialAuth: true, isLoading: false, error: null });
      },
    reloadDataUser:
      () =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });
        try {
          const resp = await reloadDataUser();
          if (resp.data && resp.data.code === 200) {
            setState({ isAuth: true, data: resp.data.payload });
            // eslint-disable-next-line no-console
            // console.log('reloadDataUser', resp.data);
          }
        } catch (err) {
          setState({ isAuth: false, data: {} });
          // console.log('reloadDataUser', err);
        }
        setState({ initialAuth: true, isLoading: false, error: null });
      },
    setMyAvailability:
      ({ id, availability }) =>
      async ({ setState }) => {
        // setState({ isLoading: true, error: null });
        try {
          // const resp = await setMyAvailability({ _id, availability });
          const resp = await setMyAvailability({ id, availability });
          // console.log('resp', resp);
          // if (resp.data && resp.data.code === 200) {
          //   setState({ isLoading: false, data: resp.data.data });
          // }
        } catch (err) {
          // setState({ isLoaalo ding: false, data: {} });
          console.log(err);
        }
      },
    login:
      ({ email, password }) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null, block: false, isBtnLogout: false });
        try {
          const resp = await loginAuth({ email, password });
          if (resp.data && resp.data.code === 200) {
            // generate tokenSocket
            const makeId = (length) => {
              let result = '';
              const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
              const charactersLength = characters.length;
              for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
              }
              return result;
            };
            window.localStorage.setItem('tokenSocket', makeId(300));

            socket.emit(socketConfig.LOGIN, {
              data: { ...resp.data.data, token: window.localStorage.getItem('tokenSocket') },
            });

            socket.on(socketConfig.LOGIN, async (data) => {
              if (data.message === 'Login Block') {
                // console.log('👮 Login Block');
                setState({ isAuth: false, data: {}, block: true });
              } else {
                const { token } = resp.data;
                window.localStorage.setItem('token', token);
                apiClient.defaults.headers = {
                  Authorization: `Bearer ${token}`,
                };

                // console.log('🔑 Login Allow');
                setState({ isAuth: true, data: resp.data.data });
              }
            });
          }
        } catch (err) {
          if (err.response.status === 400) {
            setState({
              isAuth: false,
              data: {},
              error: err.response.data || 'Error 400',
            });
          }
          if (err.response.status === 401) {
            setState({
              isAuth: false,
              data: {},
              error: err.response.data || 'Error 401',
            });
          }
          if (err.response.status === 500) {
            setState({
              isAuth: false,
              data: {},
              error: err.response.data || 'Wrong username or password',
            });
          }
        }

        setState({ isLoading: false });
      },
    // login:
    //   ({ email, password }) =>
    //   async ({ setState }) => {
    //     setState({ isLoading: true, error: null });
    //     try {
    //       const resp = await loginAuth({ email, password });
    //       if (resp.data && resp.status === 200) {

    //         setState({ isAuth: true, data: resp.data.data });
    //       }
    //     } catch (err) {
    //       if (err.response.status === 400) {
    //         setState({
    //           isAuth: false,
    //           data: {},
    //           error: err.response.data || 'Error 400',
    //         });
    //       }
    //       if (err.response.status === 401) {
    //         setState({
    //           isAuth: false,
    //           data: {},
    //           error: err.response.data || 'Error 401',
    //         });
    //       }
    //       if (err.response.status === 500) {
    //         setState({
    //           isAuth: false,
    //           data: {},
    //           error: err.response.data || 'Wrong username or password',
    //         });
    //       }
    //     }

    //     setState({ isLoading: false });
    //   },
    logout:
      () =>
      async ({ setState, getState }) => {
        setState({ initialAuth: false, isLoading: true, error: null });
        try {
          const [data, error] = await clearPNTokenWeb();
          window.localStorage.setItem('token', '');
          window.localStorage.removeItem('tokenSocket');
          apiClient.defaults.headers = {
            Authorization: '',
          };
          // unregister reg serviceWorker
          navigator.serviceWorker.getRegistrations().then((q) => {
            return Promise.all(q.map(w => w.unregister()));
          });
          console.log('🔓  logout......');
          socket.emit(socketConfig.LOGOUT, { data: getState().data });
          // setState({ isAuth: false, data: {}, error: null });
          window.location.reload();
        } catch (err) {
          setState({
            isAuth: false,
            data: {},
            error: err && err.response ? err.response.message : 'Wrong email or password',
          });
        }

        setState({ isLoading: false, initialAuth: true });
      },
  },
  // optional, mostly used for easy debugging
  name: 'auth',
});

export const useAuthStore = createHook(Store);
