import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/index.css';
import { toast } from 'react-toastify';
import { useForgotStore, useAuthStore } from '../stores';

export function FormBoxForgotPassword() {
  const [email, setEmail] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [forgotState, forgotActions] = useForgotStore();
  const [, authActions] = useAuthStore();

  const history = useHistory();
  const handleSubmit = async (event) => {
    event.preventDefault();
    authActions.updateStore({ isLoading: true });
    try {
      await forgotActions.forgot({
        email,
        onSuccess: () => {
          toast.success('We already to send link for reset passsword. Please check your email');
          authActions.updateStore({ isLoading: false });
          history.push('/');
        },
        onFailed: () => {
          toast.error('Email not yet register');
          authActions.updateStore({ isLoading: false });
        },
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <div className="w-full">
      <div className=" md:mx-10" />
      <form className="mt-5 p-5 md:p-10" onSubmit={handleSubmit}>
        <p className="label text-2xl">Forgot Password</p>
        <div className="flex items-center mt-8">
          <div className="flex  items-cente">
            Enter your email we will send you a link to get back into your account.
          </div>
        </div>
        <div className="mb-5">
          <label
            className="label block mb-2"
            style={{ marginTop: 20, fontWeight: 'normal' }}
            htmlFor="email"
          >
            Email
          </label>
          <input
            id="email"
            type="text"
            className="form-control radius w-80"
            style={{ borderRadius: 20 }}
            placeholder=""
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>

        <div>
          <button type="submit" className="btn">
            Send Reset Link
          </button>
        </div>
      </form>
    </div>
  );
}
