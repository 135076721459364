import { createStore, createHook } from 'react-sweet-state';
import { deleteReferApi, detailApi } from '../../api';

const Store = createStore({
  initialState: {
    isLoading: false,
    data: {},
    error: false,
    showDocu: false,
    docu: null,
    RXData: null,
  },
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    loadData:
      (id) =>
      async ({ setState }) => {
        setState({ isLoading: true });

        try {
          const res = await detailApi(id);
          // console.log(res, 'load detail res');

          if (res.data && res.data.code === 200) {
            setState({ data: res.data.payload.appointment, isLoading: false });
          }
        } catch (err) {
          setState({
            error: err && err.message ? err.response.message : 'Data not found',
          });
        }
      },
    deleteRefer:
      (body) =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          await deleteReferApi(body);

          setState({
            isLoading: false,
          });
        } catch (err) {
          setState({
            error: err && err.response ? err.response.message : 'Unknow add data error',
          });
        }

        setState({ isLoading: false });
      },
  },
  name: 'detailAppointmentDoctor',
});

export const useDetailStore = createHook(Store);
