import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Page403 from './components/Page403';

export default function index() {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <Page403 />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
