import React, { useEffect } from 'react';
import apologize from '../img/apologize-illustration.png';

export default function UserAgentLimit() {
  useEffect(() => {
    document.title = 'Web browser support requirement - Nadi Admin';
  }, []);
  return (
    <>
      <div className="flex">
        <div className="flex flex-col justify-center mx-auto my-14">
          <img src={apologize} className="w-full h-auto" alt="illustration" />
          <h1
            className="text-xl lg:text-2xl font-semibold text-center"
            style={{ color: '#3B4F5B' }}
          >
            Kindly please use Google Chrome browser
          </h1>
          <h1
            className="text-xl lg:text-2xl font-semibold text-center"
            style={{ color: '#3B4F5B' }}
          >
            to access this application
          </h1>
        </div>
      </div>
    </>
  );
}
