import React, { useEffect } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';

export default function PaymentResolve() {
  const { search } = useLocation();
  const isPaid = new URLSearchParams(search).get('billplz[paid]');
  const paymentStatus = isPaid === 'true';
  useEffect(() => {
    document.title = 'Payment Resolve - Nadi Admin';
  }, []);

  return (
    <>
      <Switch>
        {paymentStatus ? <Redirect to="/payment-success" /> : <Redirect to="/payment-failed" />}
      </Switch>
    </>
  );
}
